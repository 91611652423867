import React from 'react'

import { Button, Form, Modal, Segment } from '@waylay/react-components'
import { css } from '@emotion/core'

interface INewModal {
  title: string
  dismissText?: string
  submitText: string
  isSubmitting?: boolean
  onDismiss: () => void
  onSubmit: () => void
  children: React.ReactNode
}

const NewModal = ({
  title,
  dismissText = 'Cancel',
  submitText,
  isSubmitting = false,
  onDismiss,
  onSubmit,
  children,
}: INewModal) => {
  return (
    <Modal isOpen onRequestClose={onDismiss}>
      <Segment.Group>
        <Segment.Header>{title}</Segment.Header>
        <Form
          as={Segment.Group}
          css={css`
            width: 320px;
          `}
          onSubmit={onSubmit}
        >
          <Segment>{children}</Segment>
          <Modal.Actions>
            <Button outline kind="secondary" onClick={onDismiss}>
              {dismissText}
            </Button>
            <Button
              loading={isSubmitting}
              disabled={isSubmitting}
              type="submit"
            >
              {submitText}
            </Button>
          </Modal.Actions>
        </Form>
      </Segment.Group>
    </Modal>
  )
}

export default NewModal
