import { useState, useEffect } from 'react'
import { EventSourcePolyfill } from 'event-source-polyfill'
import { useLogin } from '../App/LoginContext'

import waylayClient from '~/lib/client'

interface IUseDebugOptions {
  taskId: string
}

const useDebug = (options: IUseDebugOptions) => {
  const { taskId } = options
  const { token, domain } = useLogin()
  const [connected, setConnected] = useState(true)
  const [client, setClient] = useState(null)

  const toggleConnect = () => setConnected(prevIsPlaying => !prevIsPlaying)
  const connect = () => setConnected(true)
  const disconnect = () => setConnected(false)

  useEffect(() => {
    if (!connected) return

    const client = createClient(taskId, domain, token)
    setClient(client)

    return () => client.close()
  }, [connected, options.taskId])

  return {
    client,
    connected,
    toggleConnect,
    connect,
    disconnect,
  }
}

export function createClient(taskId, domain, token) {
  const { href: url } = waylayClient.tasks.eventsUrl(taskId)

  return new EventSourcePolyfill(url, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  })
}

export default useDebug
