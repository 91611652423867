import { List, Segment } from '@waylay/react-components'
import OpenApiModal from './OpenApiWizard/OpenApiWizard'
import UploadPlug from './UploadPlug'

const PlugActionsList = () => {
  return (
    <List as={Segment} padding={0} interactive>
      <UploadPlug />
      <OpenApiModal />
    </List>
  )
}

export default PlugActionsList
