import { useEffect, useState, useRef, useMemo, useCallback } from 'react'
import { AnnotationType } from '@waylay/bn-parser'
import { createContainer } from 'unstated-next'
import { useImmerReducer } from 'use-immer'
import { find, get, omit, reduce } from 'lodash-es'
import { useAsync } from 'react-async'
import { useToasts } from 'react-toast-notifications'
import log from '../../../lib/log'
import { createClient as createDebugClient } from '../useDebug'
import { useLogin } from '../../App/LoginContext'
import client from '../../../lib/client'
import {
  PlugTypeSingular,
  isConfigurableNode,
} from '~/components/Plugins/usePlug'
import useDeclarativeBinding from './useDeclarativeBinding'
import { annotationsMap } from '~/components/Templates/Detail'
import PlugsContext from '../../Plugins/usePlugs'
import TemplateContext from './TemplateContext'
import { isValidationError } from '~/lib/types'
import { isRruleExpr } from '~/lib/util'

// this map will keep track of all the configurations we've saved on the nodes
type NodeConfiguration = Map<string, object>

const initConfig: NodeConfiguration = new Map()

enum ActionType {
  SetNode = 'UPDATE_NODE',
  RemoveNode = 'REMOVE_NODE',
  updateNodeConfig = 'UPDATE_NODE_CONFIG',
  removeNodeConfig = 'REMOVE_NODE_CONFIG',
}

interface IAction {
  type: ActionType
  payload: any
}

const getVariableValue = (type, value, defaultValue) => {
  if (
    type === 'integer' ||
    type === 'float' ||
    type === 'double' ||
    type === 'long'
  ) {
    return isNaN(value) || value === null || value === undefined
      ? defaultValue
      : value
  } else {
    return value === null || value === undefined || value === ''
      ? defaultValue
      : value
  }
}
function createVariablesObject(variables) {
  return reduce(
    variables,
    (acc, { name, value, defaultValue, type }) => {
      return {
        ...acc,
        [name]: getVariableValue(type, value, defaultValue),
      }
    },
    {},
  )
}

interface IDebugClient {
  client: any
  taskID: string
}

function useDebugClient() {
  const [debug, setDebug] = useState<IDebugClient>({
    client: undefined,
    taskID: undefined,
  })

  const setClient = client => {
    return setDebug(prev => ({ ...prev, client }))
  }

  const setTaskID = taskID => {
    return setDebug(prev => ({ ...prev, taskID }))
  }

  const clear = () => {
    setDebug({ client: undefined, taskID: undefined })
  }

  return {
    ...debug,

    setClient,
    setTaskID,
    setDebug,
    clear,
  } as const
}

function configReducer(draft: NodeConfiguration, action: IAction) {
  const { type, payload } = action

  switch (type) {
    case ActionType.updateNodeConfig: {
      const { id, values } = payload
      const nodeConfig = draft[id] || values
      draft.set(id, nodeConfig)
      break
    }
    case ActionType.removeNodeConfig: {
      const { id } = payload
      draft.delete(id)
      break
    }
    default:
      break
  }
}

function useEditor() {
  const { sub: userId, domain, token } = useLogin()
  const graph = useRef(null) // this is the @waylay/graph-editor instance
  const { addToast } = useToasts()

  const plugs = PlugsContext.useContainer()
  const {
    existingTemplateMetadata,
    nodeValidationProblems,
    fieldValidationProblems,
    setValidationProblems,
  } = TemplateContext.useContainer()

  // the focusedNodes are graph editor nodes
  const [focusedNodes, setFocusedNodes] = useState([])
  const focusedNode = focusedNodes.length === 1 ? focusedNodes[0] : null

  // yuk
  const [debugResource, setDebugResource] = useState(undefined)

  // the focusedPlug is a plug we retrieve from the plugs container and is derived
  // from the focused node
  const [focusedPlug, setFocusedPlug] = useState(null)
  const [configurations, dispatch] = useImmerReducer(configReducer, initConfig)

  const debug = useDebugClient()

  useEffect(() => {
    const validationErrors = {
      ...nodeValidationProblems,
      ...fieldValidationProblems,
    }

    Object.entries(validationErrors).forEach(([nodeLabel, errors]) => {
      const hasProblems = errors.length > 0
      const node = graph.current._findNodeByLabel(nodeLabel)
      toggleNodeAnnotation(hasProblems, node?.attrs?.id, 'error')
    })
  }, [nodeValidationProblems, fieldValidationProblems])

  // Create the simplified graph configuration
  const createGraphExport = () => {
    if (!graph.current) return

    // merge config into graph nodes
    graph.current.graph.nodes().forEach(node => {
      const nodeConfigurations = configurations.get(node.id())

      if (!nodeConfigurations) {
        log.warn('No configuration found for node', node.data())
      }

      const advancedProperties: object = get(
        nodeConfigurations,
        'advancedProperties',
      )
      const properties = omit(nodeConfigurations, ['advancedProperties'])

      const existingConfiguration = node.data('configuration') || {}
      const existingProperties: object = existingConfiguration.properties

      const keysToMillis = ['pollingPeriod', 'evictionTime']

      // transform pollingPeriod and evictionTime from seconds to milliseconds
      // and set to undefined if the field is an empty string
      const updatedAdvancedProperties: object = reduce(
        advancedProperties,
        (acc, value, key) => {
          if (keysToMillis.includes(key)) {
            const amount = parseInt(value)
            acc[key] = Number.isFinite(amount) ? value * 1000 : undefined
          } else {
            acc[key] = value
          }
          return acc
        },
        {},
      )

      node.data('configuration', {
        ...existingConfiguration,
        ...updatedAdvancedProperties,
        properties: {
          ...existingProperties,
          ...properties,
        },
      })
    })

    // export
    const simplifiedDefinition = graph.current.export()

    return simplifiedDefinition
  }

  function createGraphDefinition(
    name: string,
    attributes?: Record<string, any>,
  ) {
    if (!graph.current) return

    const graphDefinition = createGraphExport()

    // we have to copy over the name because the body has to match the URL
    // for PUT operations and is used to infer the template name for a POST operation
    // we also have to add wether or not it is a discovery template
    Object.assign(graphDefinition, {
      ...existingTemplateMetadata,
      ...(attributes ?? {}),
      name,
    })

    return graphDefinition
  }

  // 1. fetch the current bn network from the graph editor
  // 2. create a new task with the network called 'debug_<userid>'
  // 3. subscribe to the task ID (useDebug or via editorContext?) returned by 2
  // 4. show debugger controls and add spans when receiving events from 3
  const startDebug = async ({
    resource,
    taskType,
    schedule,
    pollingFrequency,
    variablesState,
    executeParallel,
    resetObservations,
    gatesNeedFullObservation,
  }) => {
    if (!graph.current) return

    const variables = createVariablesObject(variablesState)

    const network = createGraphExport()

    // the API assumes we create a task from a template when name is in the root of the object
    delete network.name
    const task: { [key: string]: any } = {
      type: taskType,
      start: false,
      name: 'debug_' + userId,
      pollingInterval: taskType === 'periodic' ? pollingFrequency : undefined,
      resource,
      variables,
      initialDelay: 0,
      executeParallel,
      resetObservations,
      gatesNeedFullObservation,
    }

    if (taskType === 'scheduled') {
      if (isRruleExpr(schedule)) {
        task.rrule = schedule
      } else {
        task.cron = schedule
      }
    }

    client.tasks
      .create(
        {
          task,
          ...network,
        },
        { failOnWarning: false, returnWarnings: true },
      )
      .then(resp => {
        const { ID: taskId, warnings } = resp
        debug.setTaskID(taskId)
        if (warnings) {
          setValidationProblems(warnings)
        }
      })
      .catch(err => {
        if (!isValidationError(err?.response?.data)) {
          const message = get(
            err,
            'response.data.error',
            'Something went wrong',
          )
          return addToast(
            <span style={{ wordBreak: 'break-word' }}>
              Failed to start debug session: <br /> {message}
            </span>,
            { appearance: 'error', autoDismiss: false },
          )
        }

        addToast(
          <span>
            Failed to start debug session: <br />
            Validation failed. See details below
          </span>,
          { appearance: 'error', autoDismiss: true },
        )

        if (isValidationError(err?.response?.data)) {
          setValidationProblems(err.response.data.details)
        }
      })
  }

  const stopDebug = async () => {
    if (debug.client) debug.client.close()

    await client.tasks.stopAndRemove(debug.taskID).catch(() => {})

    debug.clear()
  }

  // set and start SSE stream
  useEffect(() => {
    if (!debug.taskID) return
    debug.setClient(createDebugClient(debug.taskID, domain, token))
  }, [debug.taskID])

  useEffect(() => {
    if (debug.client) {
      debug.client.addEventListener('taskStopped', stopDebug)
      debug.client.onopen = () => {
        if (debug.taskID) {
          client.tasks.start(debug.taskID).catch(error => {
            const message = get(
              error,
              'response.data.error',
              'Something went wrong',
            )

            addToast(
              <span style={{ wordBreak: 'break-word' }}>
                Failed to start debug session: <br /> {message}
              </span>,
              { appearance: 'error', autoDismiss: true },
            )
          })
        }
      }
    }
  }, [debug.client, debug.taskID])

  // update the focused plug if the focusedNode has changed
  useEffect(() => {
    if (!plugs.data) return

    if (!focusedNode) {
      return setFocusedPlug(null)
    }

    const plug = focusedNode.plug
      ? find(plugs.data, focusedNode.plug)
      : undefined

    if (plug) {
      setFocusedPlug(plug)
    }
  }, [focusedNode, plugs.data])

  const updateNodeConfig = (id: string, values: object) =>
    dispatch({
      type: ActionType.updateNodeConfig,
      payload: { id, values },
    })

  const removeNodeConfig = (id: string) =>
    dispatch({
      type: ActionType.removeNodeConfig,
      payload: { id },
    })

  const focusedPlugConfig = useMemo(() => {
    // if we do not have a focusedPlug (if the task contains a plug that is not the latest version)
    // we have to infer the focusedPlugConfig from the focusedNode instead
    if (focusedNode && !focusedPlug) {
      const props = get(focusedNode, 'configuration.properties')

      if (!props) {
        return []
      }

      return Object.keys(props).map((key: string) => ({ name: key }))
    }

    return get(focusedPlug, 'configuration', [])
  }, [focusedPlug, focusedNode])

  interface BaseConfig {
    advancedProperties?: object
  }

  const focusedNodeConfig = useMemo(() => {
    if (!focusedNode) return null
    if (!focusedPlugConfig) return null

    const emptyConfig = focusedPlugConfig.reduce((acc, config) => {
      return Object.assign(acc, { [config.name]: '' })
    }, {})

    // merge configurations with properties from the node
    const baseConfig: BaseConfig =
      configurations.get(focusedNode.id) || emptyConfig

    if (isConfigurableNode(focusedNode)) {
      const advancedProperties = getFocusedNodeAdvancedProps(focusedNode)
      return Object.assign({}, { advancedProperties }, baseConfig)
    }

    return baseConfig
  }, [focusedNode, focusedPlugConfig])

  const injectNodeData = useAsync({
    deferFn: async ([data]) => {
      if (!debug.taskID) return // debug task is not started yet
      return await client.tasks.patchNode(debug.taskID, focusedNode.label, data)
    },
  })

  const getSerializedGraph = () => {
    const { sensors, actuators, ...rest } = createGraphExport()

    return {
      sensors: sensors.map(sensor => ({
        ...sensor,
        original: plugs.data.find(plug => plug.name === sensor.name),
      })),
      actuators: actuators.map(actuator => ({
        ...actuator,
        original: plugs.data.find(plug => plug.name === actuator.name),
      })),
      ...rest,
    }
  }

  const {
    schema: declarativeBindingSchema,
    isLoading: isLoadingDeclarativeBinding,
  } = useDeclarativeBinding(
    graph,
    getSerializedGraph,
    focusedNodes,
    debugResource,
  )

  const toggleNodeAnnotation = useCallback(
    (checked: boolean, nodeId: string, type: AnnotationType) => {
      if (!nodeId) return

      const img = annotationsMap[type]

      // try/catch is temporary workaround for https://sentry.io/organizations/waylay/issues/2208856789/?project=1835035&query=is%3Aunresolved
      // see also: https://github.com/waylayio/console/issues/642
      try {
        if (checked) {
          // prevent doubles
          const node = graph.current._findGraphicalNodeById(nodeId)
          if (node.data.annotations?.includes(img)) {
            return
          }

          return graph.current.addAnnotation(nodeId, img)
        }

        graph.current.deleteAnnotation(nodeId, img)
      } catch (e) {
        if (e.message.indexOf('No node with id') < 0) throw e
        console.warn(e.message)
      }
    },
    [graph, focusedNode],
  )

  return {
    graph,
    focusedNode,
    focusedNodes,
    focusedPlug,
    setFocusedNodes,
    toggleNodeAnnotation,
    configurations,
    focusedNodeConfig,
    focusedPlugConfig,

    createGraphDefinition,
    createGraphExport,

    declarativeBindingSchema,
    isLoadingDeclarativeBinding,
    setDebugResource,

    updateNodeConfig,
    removeNodeConfig,

    startDebug,
    stopDebug,
    debug,

    injectNodeData,
  } as const
}

function getFocusedNodeAdvancedProps(node) {
  if (node.type === PlugTypeSingular.Sensor) {
    const pollingPeriod = get(node, 'configuration.pollingPeriod')
    const evictionTime = get(node, 'configuration.evictionTime')

    return {
      pollingPeriod: pollingPeriod && pollingPeriod / 1000,
      evictionTime: evictionTime && evictionTime / 1000,
      sequence: get(node, 'configuration.cost', 0),
      tickTrigger: get(node, 'configuration.tickTrigger', true),
      dataTrigger: get(node, 'configuration.dataTrigger', false),
      resource: get(node, 'configuration.resource'),
      timeout: get(node, 'configuration.timeout'),
    }
  }

  if (node.type === PlugTypeSingular.Actuator) {
    return {
      policy: get(node, 'configuration.policy', 1),
    }
  }

  return {}
}

const container = createContainer(useEditor)
export const useSafeEditorContainer = (): ReturnType<
  typeof container.useContainer
> | null => {
  try {
    return container.useContainer()
  } catch {
    return null
  }
}
export default container
