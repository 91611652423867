import { useRef, useState } from 'react'
import { createContainer } from 'unstated-next'
import { v4 as uuidv4 } from 'uuid'
import _ from 'lodash-es'

export enum SimulatorStatus {
  not_started = 'not started',
  running = 'running',
  stopped = 'stopped',
}

export enum ImportType {
  manual = 'manual',
  csv = 'csv',
}
interface IResource {
  id: string
  name?: string
  tags?: []
}

export interface ISimulator {
  id: string
  name: string
  resource: IResource
  metrics: object
  settings: {
    interval: number
  }
  intervalID: NodeJS.Timeout | null
  status: SimulatorStatus
  importType: ImportType
  file: IFile
}
interface IFile {
  content: object
  name: string
  error?: string
}

const EMPTY_SIMULATOR: ISimulator = {
  id: uuidv4(),
  name: 'Sim 1',
  resource: null,
  metrics: {},
  settings: { interval: 5 * 1000 },
  intervalID: null,
  status: SimulatorStatus.not_started,
  importType: ImportType.manual,
  file: {
    content: {},
    name: '',
  },
}

const useSimulator = () => {
  const [simulators, setSimulators] = useState<ISimulator[]>([EMPTY_SIMULATOR])
  const [activeSimulator, setActiveSimulator] = useState<number>(0)
  const [isSimulatorOpen, setIsSimulatorOpen] = useState<boolean>(false)
  const [maxSize, setMaxSize] = useState<number>(1)

  // refs
  const intervalIdRef = useRef([])
  const terminalElementRef = useRef([])
  const terminalRef = useRef([])
  const fitAddonRef = useRef([])

  const setFileUploadError = (message: string) => {
    const newSimulators = _.cloneDeep(simulators)
    newSimulators[activeSimulator].file.error = message
    setSimulators(newSimulators)
  }

  const revert = () => {
    const simulatorsCopy = _.cloneDeep(simulators)
    const newSimulators = simulatorsCopy.map((simulator, index) => {
      if (simulator.intervalID) {
        clearTimeout(simulator.intervalID)
        intervalIdRef.current[index] = null
        simulator.intervalID = null
        simulator.status = SimulatorStatus.stopped
      }
      return simulator
    })
    setSimulators(newSimulators)
  }

  const removeSimulator = (index: number) => {
    delete intervalIdRef.current[index]
    delete terminalElementRef.current[index]
    delete terminalRef.current[index]
    delete fitAddonRef.current[index]

    const simulatorsCopy = _.cloneDeep(simulators)
    simulatorsCopy.splice(index, 1)

    setSimulators(simulatorsCopy)
  }

  const addSimulator = () => {
    const oldSimulators = _.cloneDeep(simulators)
    const newSimulator = _.cloneDeep(EMPTY_SIMULATOR)
    newSimulator.name = `Sim ${maxSize + 1}`
    newSimulator.id = uuidv4()
    oldSimulators.push(newSimulator)
    setSimulators(oldSimulators)
    setMaxSize(maxSize + 1)
  }

  const setProperty = (name: string, value: any) => {
    const newSimulators = _.cloneDeep(simulators)

    newSimulators[activeSimulator][name] = value

    if (name === 'intervalID') {
      if (value !== null) {
        newSimulators[activeSimulator].status = SimulatorStatus.running
      } else {
        newSimulators[activeSimulator].status = SimulatorStatus.stopped
      }
    }
    if (name === 'importType' && newSimulators[activeSimulator].intervalID) {
      newSimulators[activeSimulator].status = SimulatorStatus.stopped
      intervalIdRef.current[activeSimulator] = null
      clearTimeout(newSimulators[activeSimulator].intervalID)
      newSimulators[activeSimulator].intervalID = null
    }

    setSimulators(newSimulators)
  }

  return {
    simulators,
    setSimulators,
    addSimulator,
    setProperty,
    activeSimulator,
    setActiveSimulator,
    setIsSimulatorOpen,
    isSimulatorOpen,
    intervalIdRef,
    terminalElementRef,
    terminalRef,
    fitAddonRef,
    removeSimulator,
    revert,
    setFileUploadError,
  }
}
export default useSimulator
export const SimulatorContainer = createContainer(useSimulator)
