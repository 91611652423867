import { Select } from '@waylay/react-components'

import { IPropertyInput } from '../PropertyInput'

const EnumField = ({ property, value, setFieldValue }: IPropertyInput) => {
  const { name, format, defaultValue } = property

  const handleChange = option => {
    setFieldValue(name, option?.value || null)
  }

  return (
    <Select
      cacheOptions
      name={name}
      key={`enum-select-${value}`}
      isClearable
      options={format.values?.map(option => ({ label: option, value: option }))}
      value={value ? { label: value, value } : undefined}
      placeholder={defaultValue}
      onChange={handleChange}
    />
  )
}

export default EnumField
