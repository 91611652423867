/* eslint-disable @typescript-eslint/no-misused-promises */
import { useAsync } from 'react-async'
import { useToasts } from 'react-toast-notifications'
import { useHistory } from 'react-router-dom'
import { get, isEmpty } from 'lodash-es'
import { trigger } from 'swr'

import { createFileDownload } from '../../lib/util'
import client from '../../lib/client'

export enum PlugType {
  Sensor = 'sensors',
  Actuator = 'actuators',
  Transformer = 'transformers',
  Webscripts = 'webscripts',
}

export enum PlugTypeSingular {
  Sensor = 'sensor',
  Actuator = 'actuator',
  Tranformer = 'transformer',
  Webscript = 'Webscript',
  Gate = 'gate',
  Note = 'note',
}

export interface IPlugMetadata {
  description: string
  version: string
  type: string
}

export const isConfigurableNode = ({ type }) =>
  type === PlugTypeSingular.Sensor || type === PlugTypeSingular.Actuator

const runPlugDeferred = async (
  type: PlugType,
  name: string,
  version: string,
  data?: any,
): Promise<{
  observedState: Object
}> => {
  switch (type) {
    case PlugType.Sensor:
      return await (client.sensors.execute(
        name,
        version,
        data || {},
      ) as Promise<{
        observedState: Object
      }>)
    case PlugType.Actuator:
      return await (client.actuators.execute(
        name,
        version,
        data || {},
      ) as Promise<{
        observedState: Object
      }>)
    case PlugType.Transformer:
      return await (client.transformers.execute(
        name,
        version,
        data || {},
      ) as Promise<{
        observedState: Object
      }>)
    default:
      throw new Error('No such plug type: ' + type)
  }
}

export const createDeferred = async (
  type: PlugType,
  plug,
): Promise<{ entity: any }> => {
  return await (client[type].create(plug) as Promise<{ entity: any }>)
}

const removeDeferred = async (type: PlugType, name: string, force = false) => {
  return await client[type].remove(name, { force })
}

const removeVersionDeferred = async (
  type,
  name: string,
  version: string,
  force = false,
) => {
  return client[type].removeVersion(name, version, { force })
}

const runScriptDeferred = async (
  properties: any[],
  dependencies: object,
  script: string,
  options?: object,
) => {
  return await client.sensors.debug({
    properties,
    dependencies,
    script,
    ...options,
  })
}

function usePlug() {
  const { addToast } = useToasts()
  const history = useHistory()

  const runPlug = useAsync({
    deferFn: async ([type, name, version, data]) => {
      return await runPlugDeferred(type, name, version, data)
    },
  })

  const runScript = useAsync({
    deferFn: async ([properties, dependencies, script, options]) => {
      return await runScriptDeferred(properties, dependencies, script, options)
    },
  })

  const create = useAsync({
    deferFn: async ([type, plug]) => await createDeferred(type, plug),
    onResolve: ({ entity }) => {
      const { type, name, version } = entity
      addToast(`Successfully saved ${type} ${name} v${version}`, {
        appearance: 'success',
      })
      history.push(`/plugins/${`${type}s`}/${name}?version=${version}`)
    },
    onReject: (error: any) => {
      const message = get(error, 'response.data.error', error.message)
      addToast(`Failed to save plugin: ${message}`, { appearance: 'error' })
    },
  })

  const remove = useAsync({
    deferFn: async ([type, name, force]) =>
      await removeDeferred(type, name, force),
  })

  const removeVersion = useAsync({
    deferFn: async ([type, name, version, force]) =>
      await removeVersionDeferred(type, name, version, force),
  })

  const upload = useAsync({
    deferFn: ([type, data]) => client[type].create(data),
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    onResolve: ({ entity }) => {
      const { type, name, version } = entity
      addToast(`Successfully uploaded ${type} ${name} ${version}`, {
        appearance: 'success',
      })

      // Reload plugs
      return trigger(`${type}s`)
    },
    onReject: (error: any) => {
      const message = get(error, 'response.data.error', error.message)
      addToast(`Failed to upload: ${message}`, { appearance: 'error' })
    },
  })

  const download = useAsync({
    deferFn: ([type, name, version]) => {
      return client[type]
        .getScript(name, version)
        .then(data => ({ type, name, version, data }))
    },
    onResolve: ({ type, name, version, data }) => {
      const filename = `${type}-${name}-${version}.json`
      const json = JSON.stringify(data, null, 2)

      createFileDownload(filename, json)
    },
    onReject: (error: any) => {
      const message = get(error, 'response.data.error', error.message)
      addToast(`Failed to download: ${message}`, { appearance: 'error' })
    },
  })

  const getPlug = useAsync({
    deferFn: async ([type, name, version]) =>
      await fetchPlug(type, name, version),
  })

  const updateMetadata = useAsync({
    deferFn: ([type, name, version, metadata]) => {
      return client[type].patchMetadata(name, version, metadata)
    },
    onResolve: () => {
      addToast('Successfully updated metadata', { appearance: 'success' })
    },
    onReject: error => {
      const message = get(error, 'response.data.error', error.message)
      addToast(`Failed to update metadata: ${message}`, { appearance: 'error' })
    },
  })

  const updateDocumentation = useAsync({
    deferFn: ([type, name, version, documentation]) => {
      return client[type].patchDocumentation(name, version, documentation)
    },
    onResolve: () => {
      addToast('Successfully updated documentation', { appearance: 'success' })
    },
    onReject: error => {
      const message = get(error, 'response.data.error', error.message)
      addToast(`Failed to update documentation: ${message}`, {
        appearance: 'error',
      })
    },
  })

  async function fetchPlug(type, name, version) {
    const plug = await client[type].get(name, version)

    if (isEmpty(plug.metadata)) {
      plug.metadata = {
        documentation: {},
      }
    }

    if (isEmpty(plug.metadata?.documentation?.supportedStates)) {
      plug.metadata.documentation.supportedStates = (plug.states ?? []).map(
        state => ({
          name: state,
        }),
      )
    }

    if (isEmpty(plug.metadata?.documentation?.configuration)) {
      plug.metadata.documentation.configuration = (
        plug.configuration ?? []
      ).map(entry => ({
        name: entry.name,
      }))
    }

    if (isEmpty(plug.metadata?.documentation?.rawData)) {
      plug.metadata.documentation.rawData = (plug.rawData ?? []).map(entry => ({
        name: entry.parameter,
      }))
    }

    return await Promise.all([
      client[type].getScript(name, plug.version),
      client[type].getVersions(name, { includeDeprecated: true }),
    ]).then(([code, versions]) => ({
      metadata: plug,
      code,
      versions,
    }))
  }

  return {
    create,
    remove,
    removeVersion,
    runPlug,
    runScript,
    upload,
    download,
    updateMetadata,
    updateDocumentation,
    get: getPlug,
  }
}

export const verifyPlugExists = async ([name, type]: [
  string,
  string,
]): Promise<boolean> => {
  return client[type]
    .get(name, 'latest', { includeDeprecated: true })
    .then(() => true)
    .catch(() => false)
}

export default usePlug
