import { useMemo } from 'react'
import querystring from 'query-string'
import { useHistory, useLocation } from 'react-router-dom'
import { createContainer } from 'unstated-next'

interface ParsedQuery {
  [key: string]: string | null | undefined
}

const useQueryString = () => {
  const history = useHistory()
  const { search = '' } = useLocation()

  const query = useMemo(() => parseQuery(search), [search])

  const setQueryValue = (key: string, value = '') => {
    const search = querystring.stringify({
      ...query,
      [key]: value,
    })

    history.replace({ search })
  }

  const setQueryObject = (values: object) => {
    const search = querystring.stringify({
      ...query,
      ...values,
    })

    history.replace({ search })
  }

  return {
    query,
    setQueryValue,
    setQueryObject,
  }
}

function parseQuery(search: string): ParsedQuery {
  return querystring.parse(search, {
    decode: false,
    arrayFormat: 'comma',
  }) as ParsedQuery
}

export default createContainer(useQueryString)

export { parseQuery }
