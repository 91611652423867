import React from 'react'
import { useAsync } from 'react-async'

import { capitalize } from 'lodash-es'
import { Form, Message, Select } from '@waylay/react-components'
import { useHistory } from 'react-router-dom'
import { useModal } from 'react-modal-hook'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import NewModal from '../Common/NewModal'

import { PlugType, verifyPlugExists } from './usePlug'

interface INewModalProps {
  children: any
  type?: PlugType
}

const ModalView = ({ children, type = PlugType.Sensor }: INewModalProps) => {
  const history = useHistory()

  const createPlugin = useFormik({
    initialValues: {
      name: '',
      type,
    },
    onSubmit,
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .matches(/^[a-zA-Z0-9]+$/, 'Only letters and numbers are allowed')
        .required(),
      type: Yup.string().oneOf(Object.values(PlugType)).required(),
    }),
  })

  const { isPending, run } = useAsync({
    deferFn: verifyPlugExists,
    onResolve: nameExists => {
      if (nameExists) {
        createPlugin.setErrors({
          name: 'A plug with this name already exists',
        })
        return
      }

      const { type, name } = createPlugin.values
      history.push(`/plugins/new?type=${type}&name=${name}`)
    },
  })

  function onDismiss() {
    createPlugin.setErrors({})
    hideModal()
  }

  async function onSubmit({ name, type }: { name: string; type: PlugType }) {
    await run(name, type)
  }

  const [showModal, hideModal] = useModal(
    () => (
      <NewModal
        title="Create new plug"
        submitText="Create plug"
        isSubmitting={isPending}
        onDismiss={onDismiss}
        onSubmit={createPlugin.handleSubmit}
      >
        {createPlugin.values.type !== 'sensors' && (
          <Message kind="warning" style={{ marginBottom: '10px' }}>
            {capitalize(createPlugin.values.type)} are deprecated and will be
            removed in a future release.{' '}
          </Message>
        )}

        {type !== PlugType.Webscripts && (
          <Form.Field>
            <label htmlFor="type">Type</label>
            <TypeSelector
              value={
                createPlugin.values.type
                  ? PLUG_TYPES_OPTIONS.find(
                      type => type.value === createPlugin.values.type,
                    )
                  : null
              }
              onChange={type => createPlugin.setFieldValue('type', type)}
            />
          </Form.Field>
        )}
        <Form.Field>
          <label htmlFor="name">Name</label>
          <Form.Input.Group fluid>
            <Form.Input
              fluid
              autoFocus
              name="name"
              placeholder="name"
              id="name"
              value={createPlugin.values.name}
              onChange={createPlugin.handleChange}
            />
          </Form.Input.Group>
        </Form.Field>
        {Object.values(createPlugin.errors)[0] && (
          <Message kind="danger">
            {Object.values(createPlugin.errors)[0]}
          </Message>
        )}
      </NewModal>
    ),
    [createPlugin],
  )

  return children({ showModal })
}

const PLUG_TYPES_OPTIONS = [
  { label: 'Sensor', value: PlugType.Sensor },
  { label: 'Actuator', value: PlugType.Actuator },
  { label: 'Transformer', value: PlugType.Transformer },
]

const TypeSelector = ({ value, onChange }) => {
  const handleChange = ({ value }) => {
    typeof onChange === 'function' && onChange(value)
  }

  return (
    <Select
      id="type"
      name="type"
      iSearchable={false}
      options={PLUG_TYPES_OPTIONS}
      value={value}
      onChange={handleChange}
    />
  )
}

export default ModalView
