import React from 'react'
import { Label, Segment, Timeline } from '@waylay/react-components'
import { DateTime } from 'luxon'
import { round } from 'lodash-es'

interface INodeHistoryProps {
  timeline: ITimelineEvent[]
}

export enum TimelineEventType {
  ActuatorInvocation,
  ProbabilityChange,
}

export interface ITimelineEvent {
  timestamp: DateTime
  nodeLabel: string
  type: TimelineEventType
  error?: string
}

interface ISensorInvocation extends ITimelineEvent {
  state: string
  probability: number
}

interface IActuatorInvocation extends ITimelineEvent {
  error?: string
  node: string
}

function NodeHistory(props: INodeHistoryProps) {
  const { timeline, ...rest } = props

  return (
    <Segment.Group {...rest}>
      <Segment.Header>History</Segment.Header>
      <Segment>
        {timeline.length > 0 ? (
          <Timeline>{timeline.map(toTimelineEvent)}</Timeline>
        ) : (
          <span>No history</span>
        )}
      </Segment>
    </Segment.Group>
  )
}

const EventTitle = ({ timestamp }: { timestamp: DateTime }) => (
  <Timeline.Header>
    {timestamp.toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS)}
  </Timeline.Header>
)

function toTimelineEvent(event: ISensorInvocation | IActuatorInvocation) {
  if (isSensorInvocationEvent(event)) {
    return (
      <>
        <EventTitle timestamp={event.timestamp} />
        <Timeline.EntryGroup>
          <Timeline.Entry>
            <Label focused name={event.state}>
              {round((event.probability / 1) * 100, 2)}% probability
            </Label>
          </Timeline.Entry>
        </Timeline.EntryGroup>
      </>
    )
  }

  if (isActuatorInvocationEvent(event)) {
    return (
      <>
        <EventTitle timestamp={event.timestamp} />
        <Timeline.EntryGroup>
          <Timeline.Entry>
            {event.error ? (
              <code>{event.error}</code>
            ) : (
              <Label focused name={event.node}>
                triggered this node
              </Label>
            )}
          </Timeline.Entry>
        </Timeline.EntryGroup>
      </>
    )
  }
}

function isSensorInvocationEvent(
  event: ISensorInvocation | IActuatorInvocation,
): event is ISensorInvocation {
  return (event as ISensorInvocation).state != null
}

function isActuatorInvocationEvent(
  event: ISensorInvocation | IActuatorInvocation,
): event is IActuatorInvocation {
  return (event as IActuatorInvocation).node !== undefined
}

export default NodeHistory
