// I cannot ignore this file completely in typescript, since it's imported elsewhere
// we need to only import the types from our waylayClient, and no
// multiline typescript ignores are possible, so be ready for some duplication here.

/* eslint-disable import/no-webpack-loader-syntax */
// @ts-expect-error we need to import types here so this is necessary
import accountTypes from '!!raw-loader!@waylay/client/dist/auth/account.d.ts'
// @ts-expect-error we need to import types here so this is necessary
import actuatorsTypes from '!!raw-loader!@waylay/client/dist/actuators.d.ts'
// @ts-expect-error we need to import types here so this is necessary
import alarmsTypes from '!!raw-loader!@waylay/client/dist/alarms.d.ts'
// @ts-expect-error we need to import types here so this is necessary
import queriesTypes from '!!raw-loader!@waylay/client/dist/query.d.ts'
// @ts-expect-error we need to import types here so this is necessary
import byomlTypes from '!!raw-loader!@waylay/client/dist/byoml.d.ts'
// @ts-expect-error we need to import types here so this is necessary
import constraintsTypes from '!!raw-loader!@waylay/client/dist/constraints.d.ts'
// @ts-expect-error we need to import types here so this is necessary
import converterTypes from '!!raw-loader!@waylay/client/dist/converter.d.ts'
// @ts-expect-error we need to import types here so this is necessary
import dataTypes from '!!raw-loader!@waylay/client/dist/data.d.ts'
// @ts-expect-error we need to import types here so this is necessary
import hoardTypes from '!!raw-loader!@waylay/client/dist/hoard.d.ts'
// @ts-expect-error we need to import types here so this is necessary
import plugsTypes from '!!raw-loader!@waylay/client/dist/plugs.d.ts'
// @ts-expect-error we need to import types here so this is necessary
import resourceTypes from '!!raw-loader!@waylay/client/dist/resource.d.ts'
// @ts-expect-error we need to import types here so this is necessary
import resourcesTypes from '!!raw-loader!@waylay/client/dist/resources.d.ts'
// @ts-expect-error we need to import types here so this is necessary
import sensorsTypes from '!!raw-loader!@waylay/client/dist/sensors.d.ts'
// @ts-expect-error we need to import types here so this is necessary
import settingsTypes from '!!raw-loader!@waylay/client/dist/settings.d.ts'
// @ts-expect-error we need to import types here so this is necessary
import storageTypes from '!!raw-loader!@waylay/client/dist/storage.d.ts'
// @ts-expect-error we need to import types here so this is necessary
import tasksTypes from '!!raw-loader!@waylay/client/dist/tasks.d.ts'
// @ts-expect-error we need to import types here so this is necessary
import templatesTypes from '!!raw-loader!@waylay/client/dist/templates.d.ts'
// @ts-expect-error we need to import types here so this is necessary
import transformersTypes from '!!raw-loader!@waylay/client/dist/transformers.d.ts'
// @ts-expect-error we need to import types here so this is necessary
import vaultTypes from '!!raw-loader!@waylay/client/dist/vault.d.ts'
// @ts-expect-error we need to import types here so this is necessary
import waylayTypes from '!!raw-loader!@waylay/client/dist/waylay.d.ts'
// @ts-expect-error we need to import types here so this is necessary
import webscriptsTypes from '!!raw-loader!@waylay/client/dist/webscripts.d.ts'

import { normalizeTypeDeclarations } from './utils'

const typeFiles = [
  accountTypes,
  actuatorsTypes,
  alarmsTypes,
  queriesTypes,
  byomlTypes,
  constraintsTypes,
  converterTypes,
  dataTypes,
  hoardTypes,
  plugsTypes,
  resourceTypes,
  resourcesTypes,
  sensorsTypes,
  settingsTypes,
  storageTypes,
  tasksTypes,
  templatesTypes,
  transformersTypes,
  vaultTypes,
  webscriptsTypes,
  waylayTypes,
]

export default normalizeTypeDeclarations(typeFiles)
