import React from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { Button, Label, Message } from '@waylay/react-components'

import plugsContainer from '../Plugins/usePlugs'
import { Actions, Details } from '../Common/DetailHead'
import {
  uniquePlugsFromNetwork,
  findPlugUpdates,
  detectMissingPlugs,
  IPlug,
  IAvailablePlugUpgrade,
} from './util'
import { useMigrationWizard } from './Migration'

const MarginTop = css`
  margin-top: 1rem;
`

const MessageWrapper = styled.div`
  display: flex;
  align-items: center;
`

const Labels = styled.div`
  margin-top: 0.5rem;
`

const Alerter = ({ nodes = [], templateId, taskCount, onMigration }) => {
  const plugs = plugsContainer.useContainer()

  // early return if we didn't populate the plugs container yet
  if (!plugs.data) return null

  const plugsInUse = uniquePlugsFromNetwork(nodes)
  const latestPlugs = plugs.data

  const missingPlugs = detectMissingPlugs(plugsInUse, latestPlugs)

  const plugsToBeUpdated = findPlugUpdates(plugsInUse, latestPlugs)
  const deprecatedPlugs = plugsInUse.filter(plug => plug.type !== 'sensor')
  return (
    <div>
      {missingPlugs.length !== 0 && (
        <PlugsMessage
          plugs={missingPlugs}
          kind={MessageKind.Danger}
          title="We've detected some errors in this template"
          text="Looks like this template uses a plug that doesn't exist"
        />
      )}
      {plugsToBeUpdated.length !== 0 && (
        <MigrationMessage
          plugsToBeUpdated={plugsToBeUpdated}
          templateId={templateId}
          taskCount={taskCount}
          onMigration={onMigration}
        />
      )}
      {deprecatedPlugs.length !== 0 && (
        <PlugsMessage
          plugs={deprecatedPlugs}
          kind={MessageKind.Warning}
          text="Looks like this template uses the following actuators or transformers"
          title="Actuators and Transformers are deprecated and will be removed in a future release"
        />
      )}
    </div>
  )
}

const PlugsMessage = ({
  plugs,
  kind,
  title,
  text,
}: {
  plugs: IPlug[]
  kind: MessageKind
  title: string
  text: string
}) => (
  <Message kind={kind} title={title} css={MarginTop}>
    <Details>
      {text}:
      <Labels>
        {plugs.map(plug => (
          <Label
            key={plug.name}
            color={kind === MessageKind.Danger ? 'red' : 'orange'}
            size="small"
            css={css`
              margin-right: 0.25rem;
              margin-bottom: 0.2rem;
            `}
          >
            {plug.name} v{plug.version}
          </Label>
        ))}
      </Labels>
    </Details>
  </Message>
)

enum MessageKind {
  Danger = 'danger',
  Warning = 'warning',
}

interface IMigrationMessageProps {
  plugsToBeUpdated: IAvailablePlugUpgrade[]
  templateId: string
  taskCount: number
  onMigration: () => void
}

const MigrationMessage = ({
  plugsToBeUpdated,
  templateId,
  taskCount,
  onMigration,
}: IMigrationMessageProps) => {
  const [showWizard] = useMigrationWizard({
    availableUpgrades: plugsToBeUpdated,
    templateId,
    taskCount,
    onSuccess: onMigration,
  })

  return (
    <Message kind="info" css={MarginTop}>
      <MessageWrapper>
        <Details>
          The following plugins have newer versions available
          <Labels>
            {plugsToBeUpdated.map(plug => (
              <Label
                key={plug.name}
                color="blue"
                size="small"
                css={css`
                  margin-right: 0.25rem;
                `}
              >
                {plug.name} v{plug.newerVersion}
              </Label>
            ))}
          </Labels>
        </Details>
        <Actions>
          <Button outline onClick={() => showWizard()}>
            Migrate template
          </Button>
        </Actions>
      </MessageWrapper>
    </Message>
  )
}

export default Alerter
