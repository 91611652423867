import { css } from '@emotion/core'
import { readableColor } from 'polished'
import { concrete } from '@waylay/react-components'

import { randomColor } from '../../lib/util'

const FakeAvatar = css`
  text-align: center;
  line-height: 1em;
  text-transform: uppercase;
  font-size: inherit;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
`

const FakeImage = ({ title = '' }) => {
  const color = title ? randomColor(title) : concrete

  return (
    <div
      style={{ background: color, color: readableColor(color) }}
      css={FakeAvatar}
    >
      <span>{title.substring(0, 2)}</span>
    </div>
  )
}

export default FakeImage
