import { useEffect } from 'react'
import { useAsync } from 'react-async'

import client from '../lib/client'

export const fetchResourcesByIds = function (ids: String[]): Promise<Object> {
  let queryIds = `id:in(`
  const idsArray = ids.map(id => `"${id}"`)

  queryIds = queryIds + idsArray.join(',') + ')'
  return client.resources.search(
    { query: queryIds },
    {
      headers: { Accept: 'application/hal+json' },
    },
  )
}

export const fetchResources = function (filter: object): Promise<Object> {
  return client.resources.search(filter, {
    headers: { Accept: 'application/hal+json' },
  })
}

function useResourcesList({ filter = {}, limit, skip }) {
  const state = useAsync({
    deferFn: async ([filter]) => await fetchResources(filter),
  })

  // run the fetch when the query changes
  useEffect(
    () => state.run({ limit, skip, ...filter }),
    [JSON.stringify(filter), skip],
  )

  return state
}

export default useResourcesList
