import { useState, useRef, useEffect } from 'react'
import { Popup, List, Segment, Button, Icon } from '@waylay/react-components'

import useInterval from '~/hooks/useInterval'
import explorer from './ExplorerContainer'

enum Refresh {
  tenSeconds = '10s',
  thirtySeconds = '30s',
  oneMinute = '1m',
  fiveMinutes = '5m',
  fifteenMinutes = '15m',
}

const refreshText = {
  [Refresh.tenSeconds]: 'Every 10 seconds',
  [Refresh.thirtySeconds]: 'Every 30 seconds',
  [Refresh.oneMinute]: 'Every minute',
  [Refresh.fiveMinutes]: 'Every 5 minutes',
  [Refresh.fifteenMinutes]: 'Every 15 minutes',
}

const delays = {
  [Refresh.tenSeconds]: 10 * 1000,
  [Refresh.thirtySeconds]: 30 * 1000,
  [Refresh.oneMinute]: 60 * 1000,
  [Refresh.fiveMinutes]: 5 * 60 * 1000,
  [Refresh.fifteenMinutes]: 15 * 60 * 1000,
}

const Reload = () => {
  const { runQuery } = explorer.useContainer()
  const [refreshRate, setRefreshRate] = useState<Refresh>(null)
  const popup = useRef(null)

  useEffect(() => {
    if (popup.current) {
      popup.current.hide()
    }
  }, [refreshRate])

  useInterval(() => {
    // prevent the backend from being flooded if the previous request is still pending
    if (runQuery.isLoading) {
      return
    }

    runQuery.reload()
  }, delays[refreshRate])

  return (
    <Button.Group>
      <Button
        outline
        disabled={runQuery.isLoading}
        kind="secondary"
        onClick={() => runQuery.reload()}
      >
        <Icon name="autorenew" /> Refresh
      </Button>
      <Popup
        onCreate={instance => {
          popup.current = instance
        }}
        placement="bottom-end"
        appendTo={() => document.body}
        content={
          <List as={Segment} padding={0} interactive>
            <List.Item
              active={!refreshRate}
              onClick={() => setRefreshRate(null)}
            >
              Manually
            </List.Item>
            {Object.values(Refresh).map(rate => (
              <List.Item
                key={rate}
                active={rate === refreshRate}
                onClick={() => setRefreshRate(rate)}
              >
                {refreshText[rate]}
              </List.Item>
            ))}
          </List>
        }
      >
        <Button kind="secondary" outline disabled={runQuery.isLoading}>
          <strong>{refreshRate}</strong> <Icon name="keyboard_arrow_down" />
        </Button>
      </Popup>
    </Button.Group>
  )
}

export default Reload
