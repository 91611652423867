import React from 'react'

import ResourceSelect from '~/components/Common/ResourceSelect'
import { IPropertyInput } from '../PropertyInput'

const ResourceField = ({ property, value, setFieldValue }: IPropertyInput) => {
  const { name, defaultValue } = property

  const handleChange = ({ value }) => {
    setFieldValue(name, value)
  }

  return (
    <ResourceSelect
      resource={value}
      allowAnyInput
      resetValueOnBlur={false}
      placeholder={defaultValue}
      onChange={handleChange}
    />
  )
}

export default ResourceField
