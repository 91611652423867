import { useEffect, useRef } from 'react'
import { noop } from 'lodash-es'

const useInterval = (callback, delay: number) => {
  const savedCallback = useRef() || { current: noop }

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    const tick = () => savedCallback.current()

    if (delay == null) return

    const id = setInterval(tick, delay)
    return () => clearInterval(id)
  }, [delay])
}

export default useInterval
