import { IPlugProperty } from '~/lib/types'

function getPropertiesObject(properties: IPlugProperty[]): Object {
  return (properties || []).reduce((_, property) => {
    _[property.name] = getDefaultValueFromType(property.type)
    return _
  }, {})
}

function getDefaultValueFromType(
  type: string,
): number | boolean | Object | string {
  if (typeof type !== 'string') throw new TypeError('Type must be a string.')
  switch (type) {
    case 'boolean':
      return false
    case 'object':
      return {}
    case 'integer':
    case 'double':
    case 'long':
    case 'float':
      return 0
    case 'string':
    default:
      return '' // <string> & advanced types are treated as strings
  }
}

export const waylayClient = 'const waylay = new Waylay();'

export const waylayUtil = "const waylayUtil = require('@waylay/sandbox-util');"

export function injectOptions(properties: IPlugProperty[]) {
  return `
    const options = {
        requiredProperties: ${JSON.stringify(getPropertiesObject(properties))},
        internalState: null,
        rawData: {
        },
        task: {
            RESOURCE: ''
            TASK_ID: ''
            NODE_NAME: ''
            NODE_TRIGGERED_NAME: ''
            NODE_TRIGGERED_STATE: ''
        }
    }
`
}
