import { createContainer } from 'unstated-next'
import { useAsync } from 'react-async'

import { createFileDownload } from '../../lib/util'
import { IAvailablePlugUpgrade } from './util'
import client from '../../lib/client'

interface IAsyncProps {
  onResolve: () => void
  onReject: (err: Error) => void
}

const removeDeferred = ({ id }): Promise<Object> => {
  return client.templates.remove(id)
}

const useTemplate = () => {
  const remove = useAsync({
    deferFn: async ([id]) => await removeDeferred({ id }),
  })

  const download = async template => {
    const filename = `${template.name}.json`
    const templateDownload = await client.templates.get(template.name, {
      format: 'simplified',
    })
    const json = JSON.stringify(templateDownload, null, 2)

    createFileDownload(filename, json)
  }

  const upload = ({ onResolve, onReject }: IAsyncProps) =>
    useAsync({
      deferFn: ([template, overwrite]) => uploadTemplate(template, overwrite),
      onResolve,
      onReject,
    })

  const uploadTemplate = (template, overwrite) => {
    if (!template) return
    if (overwrite) return client.templates.update(template.name, template)
    return client.templates.create(template)
  }

  // see https://docs.waylay.io/api/rest/#modifying-existing-templates-batch
  type MigrateRunProps = [string, IAvailablePlugUpgrade[]]

  const migrate = ({ onResolve, onReject }: IAsyncProps) =>
    useAsync({
      deferFn: async ([
        templateId,
        plugsToBeUpdated,
      ]: MigrateRunProps): Promise<Object> => {
        return await client.templates.patch(
          { ids: templateId },
          {
            operation: 'updatePlugins',
            updates: plugsToBeUpdated.map(upgrade => ({
              name: upgrade.name,
              fromVersion: upgrade.version,
              toVersion: upgrade.newerVersion,
            })),
            reloadTasks: true,
          },
        )
      },
      onResolve,
      onReject,
    })

  return {
    remove,
    download,
    migrate,
    upload,
  }
}

export default useTemplate
export const TemplateContainer = createContainer(useTemplate)
