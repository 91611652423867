import styled from '@emotion/styled'
import { Button, Icon } from '@waylay/react-components'

import errorIllustration from '../../assets/images/fatal-error.png'

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;

  background: white;
`

const Illustration = styled.img`
  max-width: 100%;
`

const Error = styled.div`
  max-width: 460px;

  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto auto;
  padding: 2em;

  text-align: center;

  > * {
    margin: auto auto;
  }
`

const ErrorTitle = styled.header`
  font-size: 1.2rem;
  line-height: 2em;
  font-weight: 500;
`

const ActionButton = styled(Button)`
  margin: 2rem 0;
`

const ErrorMessage = ({
  resetErrorBoundary,
}: {
  resetErrorBoundary?: Function
}) => (
  <Container>
    <Error>
      <Illustration src={errorIllustration} />
      <ErrorTitle>Oops, something went wrong</ErrorTitle>
      <p>
        We're not quite sure what happend, but rest assured we are notified
        about this error.
      </p>

      {resetErrorBoundary && (
        <ActionButton size="large" pill onClick={resetErrorBoundary}>
          <Icon name="refresh" /> Try Again
        </ActionButton>
      )}
    </Error>
  </Container>
)

export default ErrorMessage
