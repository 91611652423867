import { css } from '@emotion/core'

const BTN_SIZE = 2.75

export const controls = css`
  .editor__controls > * {
    box-sizing: border-box;
  }

  .editor__controls {
    background: #1f2b38;

    width: ${BTN_SIZE}rem;
    height: ${BTN_SIZE * 2}rem;

    position: absolute;
    bottom: 20px;
    right: 20px;

    border-radius: 6px;
    border: solid 1px #161e27;
    overflow: hidden;
  }

  .controls__zoom {
    color: white;
    font-size: 16px;

    width: ${BTN_SIZE}rem;
    height: ${BTN_SIZE}rem;

    transition: background 150ms;
  }

  .controls__zoom:hover {
    background: #161e27;
    cursor: pointer;
  }

  .controls__zoom::before {
    text-align: center;
    line-height: ${BTN_SIZE}rem;

    position: absolute;
    height: ${BTN_SIZE}rem;
    width: ${BTN_SIZE}rem;
  }

  .controls__zoom--in {
    border-bottom: solid 1px rgba(0, 0, 0, 0.5);

    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .controls__zoom--out {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .controls__zoom--in::before {
    content: '+';
  }

  .controls__zoom--out::before {
    content: '-';
  }
`
