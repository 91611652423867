import styled from '@emotion/styled'

export const Title = styled.h1`
  font-size: 1.1rem;
  line-height: 1;
  font-weight: 500;
  margin: auto 0;
  margin-right: 1rem;
  display: inline-block;
`
