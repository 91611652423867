import { getCompletionItems } from '~/lib/declarative-binding'

const LANG = 'declarative-binding'
// const TRIGGER_CHARACTERS = ['{', '.']
const TRIGGER_CHARACTERS =
  'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ-_.$}{\\[]'.split('')

// docs on declarative binding: https://docs.waylay.io/plugins/sensors-and-actuators/#properties-declarative-binding

export const FULL_DECLARATIVE_BINDING = /\${1,2}{\??[^\r\n${}}\s]*}/ // a correct binding
export const ESCAPED_DECLARATIVE_BINDING = /\\\${1,2}{\??[^\r\n${}}\s]*}/ // an escaped binding
export const DECLARATIVE_BINDING_PROGRESS = /\${1,2}{\??[^\r\n${}}\s]*/ // matches a binding in progress. meaning not closed with '}'
export const DECLARATIVE_BINDING_MIXED_GLOBAL = /\${1,2}{\??[^\r\n${}}\s]*}?/g // matches either an open or closed binding. So either of the above.

let disposeFn

export default (monaco, schema) => {
  monaco.languages.register({ id: LANG })

  monaco.languages.setMonarchTokensProvider(LANG, {
    tokenizer: {
      root: [
        [FULL_DECLARATIVE_BINDING, 'declarative-binding'], // declarative binding
        [ESCAPED_DECLARATIVE_BINDING, 'none'], // escaped declarative binding
      ],
    },
    defaultToken: 'none',
  })

  /**
   * Check wether the user is currently writing a binding. If so, get completion items.
   */
  async function createBindingProposals(model, position) {
    const line = model.getLineContent(position.lineNumber)
    // Mind that a cursor is between indices, If the cursor is between column 3 and 4, then it will have a value of 4.
    // Also columns start at 1, not at 0, so manually shifting it one left.
    const cursorIndex = Number(position.column) - 1

    // Find all bindings (open and closed) that are on the current line
    const bindingUnderCursor = [
      ...line.matchAll(DECLARATIVE_BINDING_MIXED_GLOBAL),
    ]
      .map(binding => ({
        text: binding[0],
        startIndex: Number(binding.index),
        endIndex: Number(binding.index) + Number(binding[0].length),
        isClosed: Boolean(binding[0].endsWith('}')),
      }))
      .find(binding => {
        const afterOrAtStart = Boolean(binding.startIndex <= cursorIndex)
        const beforeOrAtEnd = Boolean(binding.endIndex >= cursorIndex)

        return afterOrAtStart && beforeOrAtEnd
      })

    if (!bindingUnderCursor) {
      return []
    }

    // If a binding exists under the cursor, splice the part before the cursor
    const bindingUntilCursor = line.substring(
      bindingUnderCursor.startIndex,
      cursorIndex,
    )

    // Send async request to fetch any completion items for the current open binding.
    const suggestions = getCompletionItems(bindingUntilCursor, schema)

    if (!suggestions) {
      return []
    }

    // Map it to valid CompletionItems
    return suggestions.map(suggestion => ({
      label: suggestion,
      kind: monaco.languages.CompletionItemKind.Text,
      documentation: suggestion,
      insertText: suggestion,

      // Trigger suggestion window only if the suggestion doesn't end with '}'
      ...(!suggestion.endsWith('}') && {
        command: { id: 'editor.action.triggerSuggest' },
      }),

      // Will replace the whole binding with whatever the user selected, even the part after the cursor.
      range: {
        startLineNumber: position.lineNumber,
        endLineNumber: position.lineNumber,
        startColumn: bindingUnderCursor.startIndex + 1, // monaco start columns at 1, instead of 0
        endColumn: bindingUnderCursor.endIndex + 1, // monaco start columns at 1, instead of 0
      },
    }))
  }

  if (typeof disposeFn === 'function') {
    disposeFn()
    disposeFn = null
  }

  const { dispose } = monaco.languages.registerCompletionItemProvider(LANG, {
    triggerCharacters: TRIGGER_CHARACTERS,
    provideCompletionItems: async (model, position) => {
      const bindingProposals = await createBindingProposals(model, position)

      return {
        suggestions: [...bindingProposals],
      }
    },
  })

  disposeFn = dispose

  return { dispose }
}
