import React from 'react'
import { IPropertyInput } from '../PropertyInput'
import EditorField from './EditorField'

const ObjectField = (props: IPropertyInput) => {
  const { setFieldValue, ...rest } = props
  const handleChange = (name, value) => {
    let parsedValue
    try {
      parsedValue = JSON.parse(value)
    } catch (e) {
      parsedValue = value
    }
    setFieldValue(name, parsedValue)
  }
  return <EditorField {...rest} setFieldValue={handleChange} language="json" />
}

export default ObjectField
