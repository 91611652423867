import { Fragment } from 'react'
import { IProperty, ITemplateVariable } from '~/lib/types'
import { FieldInputProps } from 'formik'
import { Form, Segment } from '@waylay/react-components'
import PropertyInput from '~/components/Tasks/Editor/PropertyInput'
import { isEmpty } from 'lodash-es'
import styled from '@emotion/styled'

const VariablesWrapper = styled(Segment)`
  max-height: 300px;
  overflow-y: scroll;
`

const VariablesInput = ({
  key,
  variableSpecs,
  getFieldProps,
  setFieldValue,
}: {
  key: string
  variableSpecs: ITemplateVariable[]
  getFieldProps: (name: string) => FieldInputProps<any>
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => any
}) => {
  if (isEmpty(variableSpecs)) return null
  return (
    <Fragment key={key}>
      <Segment.Header>Variables</Segment.Header>
      <VariablesWrapper>
        {variableSpecs.map(varspec => {
          const name = varspec.name
          const { name: labelKey, value } = getFieldProps(`variables.${name}`)
          const onChange = value => setFieldValue(`variables.${name}`, value)

          return (
            <Form.Field key={labelKey}>
              <label htmlFor={labelKey}>
                {varspec.displayName}
                {varspec.mandatory && !varspec.defaultValue && '*'}
              </label>
              <VariableInput type={varspec} onChange={onChange} value={value} />
            </Form.Field>
          )
        })}
      </VariablesWrapper>
    </Fragment>
  )
}

const VariableInput = ({
  value,
  type,
  onChange,
}: {
  value: any
  type: IProperty
  onChange: Function
}) => {
  return PropertyInput({
    property: type,
    value,
    setFieldValue: (name, theValue) => onChange(theValue),
  })
}

export default VariablesInput
