import styled from '@emotion/styled'
import { Tabs, clearSky, colors, carbon } from '@waylay/react-components'

const TabsItem = styled(Tabs.Item)`
  background: transparent;
  border-radius: 0;
  box-shadow: none;
  margin-bottom: 10px;
  &:hover {
    background: transparent;
    color: ${colors.withWeight(carbon, 800)};
  }

  ${props =>
    props.active &&
    `
    font-weight: 500;
    border-bottom: solid 2px ${clearSky};
  `}
`

export { TabsItem }
