export const METADATA_FILTER = [
  // these would be redundant
  'id',
  'name',
  'resourceTypeId',
  'constraintsDetails',
  'constraintsList',
  // we will visualize these in their own way
  // (Gilles): we should probably expose these until we have a better UI in place
  '_links',
  // 'tags',
  // 'metrics'
]

export const METADATA_BLACKLIST = [
  // these would be redundant
  'id',
  'name',
  'templates',
  '$constraints',
  'constraints',
  'constraintsDetails',
  'constraintsList',
  'resourceTypeConstraintsDetails',
  '$bulkOperation', // for some reason this one is returned in the HTTP response when updating
]

export const RESOURCES_INPUT_TYPES = {
  REFERENCE: 'reference',
  STRING: 'string',
  BOOLEAN: 'boolean',
  OBJECT: 'object',
  NUMBER: 'numeric',
}

export const DEFAULT_VALUE_INPUT = {
  // REFERENCE: 'reference',
  string: '',
  object: JSON.parse('{}'),
  numeric: 0,
}

export const RESOURCES_INPUT_TYPES_ARRAY = [
  {
    type: 'string',
    representation: 'abc',
  },
  {
    type: 'boolean',
    representation: 'T/F',
  },
  {
    type: 'object',
    representation: '{}',
  },
  {
    type: 'numeric',
    representation: '123',
  },
  {
    type: 'reference',
    representation: 'REF',
  },
]

export const QUERY_BUILDER_RESOURCES_SUGGESTIONS = [
  {
    key: 'id',
    description: 'Filter by resource identifier',
  },
  {
    key: 'name',
    description: 'Filter by resource name',
  },
  {
    key: 'resourceTypeId',
    description: 'Filter by resource type id',
  },
  {
    key: 'tags',
    description: 'Filter by one or many matching tags',
  },
  {
    key: 'provider',
    description: 'Filter by provider',
  },
  {
    key: 'customer',
    description: 'Filter by customer',
  },
]

export const QUERY_BUILDER_RESOURCES_OPTIONS = [
  {
    columnField: 'name',
    type: 'text',
  },
  {
    columnField: 'tags',
    type: 'text',
  },
  {
    columnField: 'id',
    type: 'text',
  },
  {
    columnField: 'provider',
    type: 'text',
  },
  {
    columnField: 'customer',
    type: 'text',
  },
  {
    columnField: 'resourceTypeId',
    type: 'text',
  },
]

export const QUERY_BUILDER_TYPES = {
  ALARMS: 'alarms',
  RESOURCES: 'resources',
  TASKS: 'tasks',
}

export const QUERY_BUILDER_TASKS_DATA = [
  {
    type: 'scheduled',
    status: 'running',
  },
  {
    type: 'periodic',
    status: 'stopped',
  },
  {
    type: 'one time',
    status: 'fail',
  },
  {
    type: 'reactive',
    status: undefined,
  },
]

export const QUERY_BUILDER_TASKS_OPTIONS = [
  {
    columnField: 'name',
    type: 'text',
  },
  {
    columnField: 'resource',
    type: 'text',
  },
  {
    columnField: 'type',
    type: 'selection',
  },
  {
    columnField: 'status',
    type: 'selection',
  },
  {
    columnField: 'id',
    type: 'text',
  },
  {
    columnField: 'plugin',
    type: 'text',
  },
  {
    columnField: 'template',
    type: 'text',
  },
  {
    columnField: 'tags',
    type: 'text',
  },
  {
    columnField: 'finishedBefore',
    type: 'text',
  },
  {
    columnField: 'createdAfter',
    type: 'text',
  },
  {
    columnField: 'createdBefore',
    type: 'text',
  },
]

export const QUERY_BUILDER_ALARMS_DATA = [
  {
    type: 'scheduled',
    status: 'active',
  },
  {
    type: 'periodic',
    status: 'acknowledged',
  },
  {
    type: 'one time',
    status: 'cleared',
  },
]

export const QUERY_BUILDER_ALARMS_OPTIONS = [
  {
    columnField: 'status',
    type: 'selection',
  },
  {
    columnField: 'source',
    type: 'text',
  },
  {
    columnField: 'dateTo',
    type: 'text',
  },
  {
    columnField: 'dateFrom',
    type: 'text',
  },
  {
    columnField: 'type',
    type: 'text',
  },
]
