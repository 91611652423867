import styled from '@emotion/styled'

export const Message = styled.div`
  width: 320px;

  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto auto;
  padding: 2em;

  text-align: center;

  > * {
    margin: auto auto;
  }
`
