import { useEffect, useMemo, useState } from 'react'

interface IFileSelectorOptions {
  accept: string
}

const useFileSelector = (
  onFile: (file: string) => any,
  options: Partial<IFileSelectorOptions> = {},
) => {
  const [file, setFile] = useState(null)

  useEffect(() => {
    if (file) {
      onFile(file)
    }
  }, file)

  function handleInputChange(e) {
    const reader = new window.FileReader()
    reader.onload = handleRead
    reader.readAsText(e.target.files[0])
  }

  function handleRead(e) {
    setFile(null)
    setFile(e.target.result)
  }

  const dummy = useMemo(() => {
    const dummyInput = document.createElement('input')
    dummyInput.type = 'file'
    dummyInput.onchange = handleInputChange

    if (options.accept) {
      dummyInput.accept = options.accept
    }

    return dummyInput
  }, [onFile])

  const open = () => {
    dummy.click()
  }

  return {
    open,
  }
}

export default useFileSelector
