import useLocalStorage from '@rehooks/local-storage'
import { createContainer } from 'unstated-next'

/**
 * this custom hook will determine wether or not the sidebar should be collapsed
 */
const useCollapsed = (initial = false) => {
  const [collapsed, setCollapsed] = useLocalStorage(
    'sidebar.collapsed',
    initial,
  )

  const toggleCollapsed = () => setCollapsed(!collapsed)

  // when the screen is smaller than a desktop, you don't get to choose
  // if the screen is big enough you can toggle between collapsed or uncollapsed
  return {
    collapsed,
    setCollapsed,
    toggleCollapsed,
  }
}

export default createContainer(useCollapsed)
