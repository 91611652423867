import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import client from '../../lib/client'
import { useModal } from 'react-modal-hook'
import { IfRejected, useAsync } from 'react-async'
import { useFormik } from 'formik'
import { v4 as createUUID } from 'uuid'
import {
  Button,
  Icon,
  Form,
  Modal,
  Segment,
  Message,
  Tooltip,
} from '@waylay/react-components'
import { useEffect } from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { IErrorResponse } from '~/lib/types'

interface IReponse {
  statusCode: number
  uri: string
  entity: {
    id: string
    name: string
  }
}

const createConstraintDeferred = ([values]) => {
  return client.constraints.create(values)
}

export default function SaveDialog() {
  const history = useHistory()

  const [showModal, hideModal] = useModal(() => {
    const createConstraint = useAsync({
      deferFn: createConstraintDeferred,
      onResolve: (response: IReponse) => {
        history.push(`/resourceconstraints/${response.entity.id}`)
      },
    })

    const formik = useFormik({
      initialValues: {
        id: createUUID(),
        name: '',
        attributes: [],
      },
      onSubmit: values => createConstraint.run(values),
      validationSchema: Yup.object().shape({
        name: Yup.string().required(),
      }),
    })

    useEffect(() => {
      createConstraint.promise.then(() => hideModal()).catch(() => {})
    }, [createConstraint.promise])

    return (
      <Modal
        isOpen
        onRequestClose={hideModal}
        onAfterClose={() => formik.resetForm()}
      >
        <Form onSubmit={formik.handleSubmit}>
          <Segment.Group
            css={css`
              width: 600px;
            `}
          >
            <Segment.Header>Create constraint</Segment.Header>
            <Segment>
              <label htmlFor="name">Name</label>
              <FluidGroup>
                <Form.Input
                  name="name"
                  fluid
                  autoFocus
                  value={formik.values.name}
                  onChange={formik.handleChange}
                />
              </FluidGroup>
              <label htmlFor="id">Identifier</label>
              <FluidGroup>
                <Form.Input
                  name="id"
                  fluid
                  value={formik.values.id}
                  onChange={formik.handleChange}
                />
                <Tooltip content="This is the unique identifier of the resource">
                  <Form.Input.Adornment
                    right
                    css={css`
                      display: inline-flex;
                    `}
                  >
                    <Icon name="help" />
                  </Form.Input.Adornment>
                </Tooltip>
              </FluidGroup>
              <div
                css={css`
                  margin-top: 0.5rem;
                `}
              >
                {Object.values(formik.errors)[0] && (
                  <Message kind="danger">
                    {Object.values(formik.errors)[0]}
                  </Message>
                )}
                <IfRejected state={createConstraint}>
                  {(error: IErrorResponse) => (
                    <Message kind="danger">{error.response.data.error}</Message>
                  )}
                </IfRejected>
              </div>
            </Segment>
            <Modal.Actions>
              <Button outline kind="secondary" onClick={hideModal}>
                Cancel
              </Button>
              <Button
                type="submit"
                kind="primary"
                loading={createConstraint.isLoading}
                disabled={createConstraint.isLoading}
                onClick={formik.handleSubmit}
              >
                Create constraint
              </Button>
            </Modal.Actions>
          </Segment.Group>
        </Form>
      </Modal>
    )
  }, [])

  return {
    showModal,
    hideModal,
  }
}

const FluidGroup = styled(Form.Input.Group)`
  display: inline-flex;
  align-items: center;
  width: 100%;
`
