import { DateTime, Duration, Interval } from 'luxon'

export const DT_FORMAT = 'yyyy-LL-dd hh:mm a'

export function parseDateToISO(date: string): string | null {
  if (date) {
    if (DateTime.fromISO(date).isValid) {
      return DateTime.fromISO(date).toISO()
    }

    if (DateTime.fromFormat(date, DT_FORMAT).isValid) {
      return DateTime.fromFormat(date, DT_FORMAT).toISO()
    }

    if (Duration.fromISO(date).isValid) {
      const duration = Duration.fromISO(date)

      return DateTime.now().plus(duration).toISO()
    }
  }

  return null
}

// retrieve the absolute start and end for a combination of "from", "until" and "window"
export function getInterval(from?: string, until?: string, window?: string) {
  let intervalFrom, intervalUntil

  if (from && until) {
    intervalFrom = resolveTimeDefinition(from)
    intervalUntil = resolveTimeDefinition(until)
  }

  if (from && window && !until) {
    intervalFrom = resolveTimeDefinition(from).plus(Duration.fromISO(window))
    intervalUntil = resolveTimeDefinition('now')
  }

  if (until && window && !from) {
    intervalFrom = resolveTimeDefinition(until).minus(Duration.fromISO(window))
    intervalUntil = resolveTimeDefinition('now')
  }

  if (window && !until && !from) {
    intervalFrom = resolveTimeDefinition('now').minus(Duration.fromISO(window))
    intervalUntil = resolveTimeDefinition('now')
  }

  if (from && until && window) {
    throw new Error('Cannot have "from", "until" and "window"')
  }

  const interval = Interval.fromDateTimes(intervalFrom, intervalUntil)

  return interval
}

// convert any valid "from" / "until" string to a DateTime
export function resolveTimeDefinition(time: string): DateTime | undefined {
  const isDateTime =
    DateTime.fromISO(time).isValid ||
    DateTime.fromFormat(time, DT_FORMAT).isValid
  const isDuration = Duration.fromISO(time).isValid
  const isNow = time === 'now'

  if (isDateTime) {
    return DateTime.fromISO(time).isValid
      ? DateTime.fromISO(time)
      : DateTime.fromFormat(time, DT_FORMAT)
  } else if (isDuration) {
    return DateTime.local().plus(Duration.fromISO(time))
  } else if (isNow) {
    return DateTime.local()
  }
}

export function toFormattedDate(date?: DateTime) {
  return date ? date.toFormat(DT_FORMAT) : date
}

export function formatDate(value: string) {
  if (value) {
    if (DateTime.fromISO(value).isValid) {
      return DateTime.fromISO(value).toFormat(DT_FORMAT)
    }
  }

  return value
}
