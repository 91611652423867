import React from 'react'
import styled from '@emotion/styled'
import { colors, concrete, Icon } from '@waylay/react-components'

const StyledBox = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-radius: 5px;
  height: auto;
  vertical-align: middle;
  padding-top: 5px;
  padding-bottom: 5px;
  border-left: solid 10px ${props => props.color};
  border-top: solid 1px ${colors.withWeight(concrete, 500)};
  border-bottom: solid 1px ${colors.withWeight(concrete, 500)};
  border-right: solid 1px ${colors.withWeight(concrete, 500)};
`

export enum BoxType {
  information,
  error,
  success,
}

const Content = ({
  iconName,
  title,
  text,
  color,
}: {
  iconName: string
  title?: string
  text?: string
  color: string
}) => {
  return (
    <StyledBox color={color} data-testid="box">
      <Icon
        data-testid="box-icon"
        name={iconName}
        color={color}
        circular
        style={{ marginLeft: '10px', fontSize: '0.8em' }}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginLeft: '10px',
        }}
      >
        <div
          data-testid="box-title"
          style={{
            fontSize: '0.9em',
            fontWeight: 'bold',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
          }}
        >
          {title}
        </div>
        <div
          data-testid="box-text"
          style={{ fontSize: '0.8em', lineHeight: '0.8em' }}
        >
          {text}
        </div>
      </div>
    </StyledBox>
  )
}
const Box = ({
  type,
  title,
  text,
}: {
  type: BoxType
  title?: string
  text?: string
}) => {
  return (
    <div data-testid="validation-error">
      {type === BoxType.success && (
        <Content color="#47bb78" iconName="check" text={text} title={title} />
      )}

      {type === BoxType.error && (
        <Content
          color="#ec584b"
          iconName="priority_high"
          text={text}
          title={title}
        />
      )}
      {type === BoxType.information && (
        <Content color="#1c90ff" iconName="folder" text={text} title={title} />
      )}
    </div>
  )
}

export default Box
