import React from 'react'

interface IPendingProps {
  state: IState
  children?: React.ReactNode
}

interface IRejectedProps {
  state: IState
  children?: React.ReactNode | ((error: Error) => React.ReactNode)
}

interface IFulfilledProps {
  state: IState
  children?: React.ReactNode | ((data: any) => React.ReactNode)
}

interface IState {
  error?: Error
  data?: any
  loading?: boolean
  isValidating?: boolean
}

const isLoading = (state: IState) => state.loading ?? state.isValidating

const IfPending = ({ state, children }: IPendingProps) => {
  const isPending = isLoading(state)
  const hasStaleData = state.data

  return isPending && !hasStaleData ? <>{children}</> : null
}

const IfRejected = ({ state, children }: IRejectedProps) => {
  const isRejected = state.error && !isLoading(state)
  if (!isRejected) return null
  if (typeof children === 'function') return children(state.error)
  return children
}

const IfFulfilled = ({ state, children }: IFulfilledProps) => {
  const isFulfilled = !state.error && state.data
  if (!isFulfilled) return null
  if (typeof children === 'function') return children(state.data)
  return children
}

export { IfPending, IfRejected, IfFulfilled }
