import React from 'react'
import { IntlProvider } from 'react-intl'
import { ToastProvider } from 'react-toast-notifications'

import { CacheProvider, Global } from '@emotion/core'
import createCache from '@emotion/cache'
import { HelmetProvider } from 'react-helmet-async'
import { ModalProvider } from 'react-modal-hook'

import { Global as GlobalStyles } from './App.styles'

import flags, { FlagsProvider } from '../../lib/flags'
import ProfileContainer from '../Dashboard/Sidebar/useProfile'

function Context({ children, stylesContainer }) {
  const emotionCache = createCache({
    key: 'dashboard',
    container: stylesContainer,
  })

  return (
    <FlagsProvider flags={flags}>
      <IntlProvider locale={navigator.language}>
        <ToastProvider
          autoDismiss
          autoDismissTimeout={3000}
          placement="top-right"
        >
          <ProfileContainer.Provider>
            <CacheProvider value={emotionCache}>
              <HelmetProvider>
                <Global styles={GlobalStyles} />
                <ModalProvider>{children}</ModalProvider>
              </HelmetProvider>
            </CacheProvider>
          </ProfileContainer.Provider>
        </ToastProvider>
      </IntlProvider>
    </FlagsProvider>
  )
}

export default Context
