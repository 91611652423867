import { useAsync } from 'react-async'
import { useEffect, useState } from 'react'
import _, { isEqual, isUndefined, omitBy } from 'lodash-es'
import log from '~/lib/log'
import client from '~/lib/client'
import { usePrevious } from 'react-delta'

function useDeclarativeBinding(
  graph: any,
  getSerializedGraph: Function,
  focusedNodes: any,
  resource?: any,
) {
  const [currentSerializedGraph, setCurrentSerialzedGraph] = useState()
  const prevSerializedGraph = usePrevious(currentSerializedGraph)
  const [currentFocussedNode, setFocusedNode] = useState()
  const prevCurrentFocussedNode = usePrevious(currentFocussedNode)

  const {
    data: schema,
    isLoading,
    run,
  } = useAsync({
    deferFn: ([serializedGraph]) => {
      const newGraph = _.cloneDeep(serializedGraph)
      newGraph.sensors = newGraph.sensors.map(sensor =>
        _.omit(sensor, 'original'),
      )
      newGraph.actuators = newGraph.actuators.map(actuator =>
        _.omit(actuator, 'original'),
      )
      return client.templates.declarativeBindings(newGraph)
    },
    onReject: onError,
  })

  useEffect(() => {
    if (!graph.current) {
      return
    }

    try {
      setCurrentSerialzedGraph(getSerializedGraph())
      setFocusedNode(
        focusedNodes && focusedNodes.length === 1
          ? focusedNodes[0].label
          : undefined,
      )
    } catch (err) {
      // The editor currently has an invalid graph that can't be serialized.
      // Keep the old suggestions until graph is valid again.
    }
  }, [graph.current, focusedNodes])

  useEffect(() => {
    if (
      !graph.current ||
      (isEqual(currentSerializedGraph, prevSerializedGraph) &&
        isEqual(currentFocussedNode, prevCurrentFocussedNode))
    ) {
      return
    }

    try {
      const settings = omitBy(
        { selectedNode: currentFocussedNode, taskResource: resource },
        isUndefined,
      )
      const payload = {
        ...(currentSerializedGraph as Record<string, unknown>),
        settings,
      }
      run(payload)
    } catch (err) {
      onError(err)
    }
  }, [graph.current, currentSerializedGraph, currentFocussedNode])

  return { schema, isLoading }
}

function onError(err: Error) {
  log.warn({ err }, 'Failed to load declarative binding suggestions, ignoring.')
}

export default useDeclarativeBinding
