import React from 'react'

import { Select } from '@waylay/react-components'
import { useVault } from '~/components/Settings/Organisation/GeneralSettings/useVault'

const VaultSelect = ({ onChange, value, placeholder }) => {
  const { data = [], loading } = useVault()

  const options = data.map(setting => ({
    label: setting.key,
    value: setting.key,
  }))

  const selectProps = {
    onChange,
    options,
    placeholder,
    isLoading: loading,
    isClearable: true,
    defaultValue: value ? { label: value, value } : undefined,
  }

  return <Select {...selectProps} />
}

export default VaultSelect
