import jp from 'jsonpath'
import { trimEnd, flatMap, uniq, compact } from 'lodash-es'

export function getCompletionItems(openBinding, schema) {
  if (!schema) {
    return []
  }

  // binding just opened, early return top items
  if (openBinding === '${') {
    return Object.keys(schema).map(key => '${' + key)
  }

  // binding just opened, early return top items
  if (openBinding === '$${') {
    return Object.keys(schema).map(key => '$${' + key)
  }

  const trimmedOpenBinding = trimEnd(openBinding, '.')

  let results = []

  // try to resolve the jp.query as is. This actually allows the binding to have special paths like ${nodes..test}
  try {
    // json path is $. instead of ${
    const jsonPathStart = trimmedOpenBinding.replace(/\$?\$\{/, '$.')
    results = jp.query(schema, jsonPathStart)
  } catch (_) {}

  // No further suggestions, add ending
  if (compact(results).length === 0) {
    return [`${trimmedOpenBinding}}`]
  }

  // Map through all suggestion levels
  const suggestions = uniq(flatMap(results, result => Object.keys(result)))
    // Combine each valid next key with the current open binding to have valid suggestions.
    .map(result => `${trimmedOpenBinding}.${result}`)

  // Remove self reference.
  const referenceToSelfIndex = suggestions.indexOf(trimmedOpenBinding)
  if (referenceToSelfIndex !== -1) {
    suggestions.splice(referenceToSelfIndex, 1)
  }

  return suggestions
}
