import { AsyncSelect } from '@waylay/react-components'

import client from '../../lib/client'

export const findTemplates = async (filter: string) => {
  const templates = await client.templates.list(filter)

  return ((templates as any[]) || []).map(template => ({
    ...template,
    label: template.name,
    value: template.name,
  }))
}

interface ITemplateSelect {
  name?: string
  template?: string
  onChange: Function
  isMulti?: boolean
  placeholder?: string
}

const noOptionsMessage = ({ inputValue }) =>
  inputValue.length
    ? `No templates found containing '${inputValue}'`
    : 'Start typing…'

const TemplateSelect = (props: ITemplateSelect) => {
  const { template, onChange, ...rest } = props

  const loadOptions = async filter => await findTemplates(filter)

  return (
    <AsyncSelect
      cacheOptions
      defaultOptions
      isClearable
      key={`template-select-${template}`}
      defaultValue={template ? { label: template, value: template } : undefined}
      noOptionsMessage={noOptionsMessage}
      loadOptions={loadOptions}
      onChange={v => onChange(v || {})}
      {...rest}
    />
  )
}

export default TemplateSelect
