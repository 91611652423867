import pino from 'pino'

const level =
  process.env.PINO_LOG_LEVEL || process.env.NODE_ENV === 'development'
    ? 'debug'
    : 'warn'

export default pino({
  level,
  browser: {
    asObject: true,
  },
})
