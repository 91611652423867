import { get } from 'lodash-es'
import useSWR, { mutate } from 'swr'
import useMutation from '~/hooks/useMutation'
import client from '~/lib/client'
import { ITemplate } from '~/lib/types'

const TEMPLATES_SWR_KEY = 'templates'

interface ITemplateDetails {
  template: ITemplate
  taskCount: number
  resourceTypes: any[]
}

const fetchTemplate = async ({ id }): Promise<ITemplateDetails> => {
  const { REACT_APP_WAYLAY_RESOURCES_URL, REACT_APP_WAYLAY_API_GATEWAY_URL } =
    window.env

  const template = await (client.templates.get(id, {
    format: 'bn',
  }) as unknown as Promise<ITemplate>)

  const taskCount = await client.tasks.count({ template: template.name })

  const resourceTypes =
    REACT_APP_WAYLAY_API_GATEWAY_URL || REACT_APP_WAYLAY_RESOURCES_URL
      ? await client.types
          .list({ template: String(template.name) })
          .then(data => get(data, 'values', []))
      : []

  return { template, taskCount, resourceTypes }
}

const usePromoteToDiscoveryTemplate = (
  key: string | string[],
  details: ITemplateDetails,
) => {
  return useMutation(
    async () => {
      await client.templates.setDiscoveryTemplate(details.template.name)
      return details
    },
    {
      onSuccess: () => {
        const newTemplate = {
          ...details,
          template: { ...details.template, discoveryTemplate: true },
        }
        mutate(key, newTemplate, false)
      },
    },
  )
}

// remove the discovery template (there can be only one)
const useDemoteDiscoveryTemplate = (
  key: string | string[],
  details: ITemplateDetails,
) => {
  return useMutation(
    async () => {
      // empty params will remove the discovery template
      await client.templates.setDiscoveryTemplate()

      return details
    },
    {
      onSuccess: () => {
        const newTemplate = {
          ...details,
          template: { ...details.template, discoveryTemplate: false },
        }
        mutate(key, newTemplate, false)
      },
    },
  )
}

export const fetchRunningTasks = ({ template }: { template: string }) => {
  return client.tasks.list({ template })
}

const useTemplateFetch = (id: string) => {
  const key = [TEMPLATES_SWR_KEY, id]

  const { isValidating, data, error } = useSWR(
    key,
    async () => await fetchTemplate({ id }),
  )

  const reload = async () => await mutate(key)
  const promoteToDiscoveryTemplate = usePromoteToDiscoveryTemplate(key, data)
  const demoteDiscoveryTemplate = useDemoteDiscoveryTemplate(key, data)

  return {
    loading: isValidating,
    error,
    data,
    reload,
    promoteToDiscoveryTemplate,
    demoteDiscoveryTemplate,
  }
}

export default useTemplateFetch
