import { useAsync } from 'react-async'

import client from '../../lib/client'
import { IAvailablePlugUpgrade, IPlug } from './util'

const fetchVersions = async ({ plugs }) => {
  const fetch = plugs.map(({ name, type }) =>
    client[`${type}s`].getVersions(name),
  )

  const results = await Promise.all(fetch)

  // -> { "sensor1": ['1.0.0', '1.0.1'] }
  return results.reduce((acc: Object = {}, plugs: IPlug[]) => {
    const name = plugs[0].name
    const versions = plugs.map(p => p.version)

    return {
      ...acc,
      [name]: versions,
    }
  }, {})
}

export default function availablePlugs(
  plugs: IAvailablePlugUpgrade[],
  options,
) {
  return useAsync(fetchVersions, {
    plugs,
    onResolve: options.onResolve,
    onReject: options.onReject,
  })
}
