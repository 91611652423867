const RULES = [
  { token: 'declarative-binding', foreground: 'ffa500', fontStyle: 'bold' },
  {
    token: 'declarative-binding-special',
    foreground: 'ffe500',
    fontStyle: 'bold',
  },
]

export default monaco => {
  monaco.editor.defineTheme('waylay-dark', {
    base: 'vs-dark',
    inherit: true,
    rules: RULES,
    colors: {
      'editor.foreground': '#FFFFFE',
      'editor.background': '#353536',
    },
  })

  monaco.editor.defineTheme('waylay-light', {
    base: 'vs',
    inherit: true,
    rules: RULES,
    colors: {
      'editor.foreground': '#000000',
      'editor.background': '#FFFFFE',
    },
  })
}
