import React, { useEffect } from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { useModal } from 'react-modal-hook'
import { IfRejected, useAsync } from 'react-async'
import { Formik, FieldArray, useFormikContext } from 'formik'
import { useHistory } from 'react-router-dom'
import {
  Button,
  Icon,
  Form,
  Modal,
  Segment,
  Message,
  Tooltip,
  Toggle,
  Select,
  concrete,
} from '@waylay/react-components'
import { v4 as createUUID } from 'uuid'
import { get, map, last, isEmpty, has } from 'lodash-es'

import client from '../../lib/client'
import ResourceSelect from '~/components/Common/ResourceSelect'
import ResourceTypeSelect from '~/components/Common/ResourceTypeSelect'
import { ConstraintTypes, IErrorResponse } from '~/lib/types'

const createResourceDeferred = ([values]) => client.resources.create(values)
const fetchResourceType = ({ id }) => client.types.get(id)
const fetchConstraints = ({ id }) => client.types.constraints(id)

interface IResponse {
  statusCode: number
  uri: string
  entity: {
    id: string
    name: string
  }
}

// Note: form currently relies on serverside validation
const NewResourceModal = () => {
  const history = useHistory()

  const createResource = useAsync({
    deferFn: createResourceDeferred,
    onResolve: (response: IResponse) => {
      hideModal()
      history.push(`/resources/${encodeURIComponent(response.entity.id)}`)
    },
  })

  useEffect(() => {
    createResource.promise.catch(() => {
      /* Form will show errors returned by api to user */
    })
  }, [createResource.promise])

  const [showModal, hideModal] = useModal(
    () => (
      <Modal isOpen onRequestClose={hideModal}>
        <Formik
          initialValues={{
            id: createUUID(),
            name: '',
            resourceTypeId: undefined,
          }}
          onSubmit={values => createResource.run(values)}
        >
          {formik => (
            <Form onSubmit={formik.handleSubmit}>
              <Segment.Group
                css={css`
                  width: 600px;
                `}
              >
                <Segment.Header>Create resource</Segment.Header>
                <Segment>
                  <Form.Field>
                    <FormLabel htmlFor="name">Name</FormLabel>
                    <FormGroup>
                      <Form.Input
                        name="name"
                        fluid
                        autoFocus
                        value={formik.values.name}
                        onChange={formik.handleChange}
                      />
                    </FormGroup>
                  </Form.Field>
                  <Form.Field>
                    <FormLabel htmlFor="id">Identifier</FormLabel>
                    <FormGroup>
                      <Form.Input
                        name="id"
                        fluid
                        value={formik.values.id}
                        onChange={formik.handleChange}
                      />
                      <Tooltip content="This is the unique identifier of the resource">
                        <Form.Input.Adornment
                          right
                          css={css`
                            display: inline-flex;
                          `}
                        >
                          <Icon name="help" />
                        </Form.Input.Adornment>
                      </Tooltip>
                    </FormGroup>
                  </Form.Field>
                  <Form.Field>
                    <FormLabel htmlFor="resourceTypeId">
                      Resource type
                    </FormLabel>
                    <ResourceTypeSelect
                      value={formik.values.resourceTypeId}
                      onChange={({ value }) =>
                        formik.setFieldValue('resourceTypeId', value)
                      }
                    />
                  </Form.Field>

                  <Attributes />

                  <div
                    css={css`
                      margin-top: 0.5rem;
                    `}
                  >
                    {Object.values(formik.errors)[0] && (
                      <Message kind="danger">
                        {Object.values(formik.errors)[0]}
                      </Message>
                    )}
                    <IfRejected state={createResource}>
                      {(error: IErrorResponse) => (
                        <ErrorMessage error={error} />
                      )}
                    </IfRejected>
                  </div>
                </Segment>
                <Modal.Actions>
                  <Button outline kind="secondary" onClick={() => hideModal()}>
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    kind="primary"
                    loading={createResource.isLoading}
                    disabled={createResource.isLoading}
                    onClick={formik.handleSubmit}
                  >
                    Create resource
                  </Button>
                </Modal.Actions>
              </Segment.Group>
            </Form>
          )}
        </Formik>
      </Modal>
    ),
    [createResource.isLoading, createResource.error],
  )

  return {
    showModal,
    hideModal,
  }
}

const ErrorMessage = ({ error }: { error: IErrorResponse }) => {
  const errors = has(error, 'response.data.details')
    ? get(error, 'response.data.details', []).map(error => get(error, 'msgs.0'))
    : [get(error, 'response.data.error', 'Something went wrong')]

  return (
    <Message kind="danger">
      <StyledErrors>
        {errors.map((error, index) => (
          <li key={`error-${index}`}>{error}</li>
        ))}
      </StyledErrors>
    </Message>
  )
}

const StyledErrors = styled.ul`
  margin: 0;
  padding: 0;
  padding-left: 1em;
`

const Attributes = () => {
  const { values } = useFormikContext<{ resourceTypeId: string }>()
  const { resourceTypeId } = values

  const constraintsFetch = useAsync(fetchConstraints, {
    id: resourceTypeId,
    watch: resourceTypeId,
  })
  const attributes = get(constraintsFetch, 'data.attributes', [])

  const resourceTypeFetch = useAsync(fetchResourceType, {
    id: resourceTypeId,
    watch: resourceTypeId,
  })
  const resourceType = get(resourceTypeFetch, 'data')

  const filteredAttributes = attributes
    .filter(attr => attr.required)
    .filter(attr => attr.name !== 'id')

  if (isEmpty(filteredAttributes)) return null

  return (
    <>
      <FormLabel style={{ marginTop: '1.5em' }}>
        Additional attributes
      </FormLabel>
      <AttributesForm
        attributes={filteredAttributes}
        resourceType={resourceType}
      />
    </>
  )
}

const AttributesForm = ({ attributes, resourceType = {} }) => {
  return attributes.map(attr => {
    const { name } = attr
    const placeholder = get(resourceType, name)

    return (
      <AttributeInput key={name} attribute={attr} placeholder={placeholder} />
    )
  })
}

const AttributeInput = ({ attribute, placeholder }) => {
  const type = get(attribute, 'type.type')
  const Input = getTypeInput(type)

  const { name } = attribute
  const label = last(name.split('.'))

  return (
    <Form.Field key={name}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Input
        name={name}
        placeholder={placeholder || label}
        attribute={attribute}
      />
    </Form.Field>
  )
}

interface IAbstractInput {
  name: string
  placeholder?: string
  attribute?: any
}

const getTypeInput = (type: ConstraintTypes): React.FC<IAbstractInput> => {
  switch (type) {
    case ConstraintTypes.string:
      return StringInput
    case ConstraintTypes.numeric:
      return NumberInput
    case ConstraintTypes.boolean:
      return BooleanInput
    case ConstraintTypes.enum:
      return EnumInput
    case ConstraintTypes.resourceRef:
      return ResourceRefInput
    case ConstraintTypes.object:
      return ObjectInput
    case ConstraintTypes.array:
      return ArrayInput
    default:
      return StringInput
  }
}

const StringInput = ({ name, placeholder }) => {
  const { values, handleChange } = useFormikContext()
  const value = get(values, name)

  return (
    <FormGroup>
      <Form.Input
        name={name}
        type="text"
        placeholder={placeholder}
        fluid
        value={value}
        onChange={handleChange}
      />
    </FormGroup>
  )
}

const NumberInput = ({ name, placeholder }) => {
  const { values, handleChange } = useFormikContext()
  const value = get(values, name)

  return (
    <FormGroup>
      <Form.Input
        name={name}
        type="number"
        placeholder={placeholder}
        fluid
        value={value}
        onChange={handleChange}
      />
    </FormGroup>
  )
}

const BooleanInput = ({ name }) => {
  const { values, setFieldValue } = useFormikContext()
  const value = get(values, name)

  return (
    <Toggle
      checked={value}
      onChange={e => setFieldValue(name, e.target.checked)}
    />
  )
}

const EnumInput = ({ name, attribute }) => {
  const { values, setFieldValue } = useFormikContext()

  const items = attribute?.type?.items ?? []
  const options = items.map(item => ({ label: item, value: item }))

  const formikValue = get(values, name)
  const valueOption = options.find(option => option.value === formikValue)

  return (
    <Select
      value={valueOption}
      options={options}
      onChange={({ value }) => setFieldValue(name, value)}
    />
  )
}

const ResourceRefInput = ({ name }) => {
  const { values, setFieldValue } = useFormikContext()
  const value = get(values, name)

  return (
    <ResourceSelect
      name={name}
      resource={value}
      onChange={({ value }) =>
        setFieldValue(name, { $ref: `/resources/${value}` })
      }
    />
  )
}

const ObjectInput = ({ name, attribute }) => {
  const formik = useFormikContext()

  // extra default because of object arrays
  const attributes = (
    get(attribute, 'type.attributes') ||
    get(attribute, 'type.elementType.attributes') ||
    []
  ).map(attribute => ({ ...attribute, name: `${name}.${attribute.name}` }))

  /*
   * Set default to empty object to handle the situation where the object
   * property is required but non of its own (sub) attributes are required
   * resulting in the property being undefined rather than a emtpy object
   */
  useEffect(() => {
    formik.setFieldValue(name, {})
  }, [])

  return (
    <ObjectInputContainer>
      <AttributesForm attributes={attributes} />
    </ObjectInputContainer>
  )
}

const ObjectInputContainer = styled.div`
  width: 100%;
  margin-left: 0.4em;
  border-left: 2px solid ${concrete};
  border-radius: 1px;
  padding-left: 1.2em;
`

const ArrayInput = ({ name, attribute }) => {
  const { values } = useFormikContext()

  // Note: Exception for boolean is required as the array will
  // otherwise be a line of toggles
  const elementType = get(attribute, 'type.elementType.type')
  const Input =
    elementType === ConstraintTypes.boolean
      ? BooleanArrayInput
      : getTypeInput(elementType)

  const items = values[name]

  const pushItem = elementType === ConstraintTypes.object ? {} : ''

  return (
    <FieldArray
      name={name}
      render={arrayHelpers => (
        <div>
          {map(items, (_, index: number) => {
            const itemName = `${name}.${index}`
            const props =
              elementType === ConstraintTypes.object
                ? { name: itemName, attribute }
                : { name: itemName }

            return (
              <ArrayItem key={itemName}>
                <Input {...props} />
                <RemoveItem
                  kind="secondary"
                  onClick={() => arrayHelpers.remove(index)}
                  style={{ marginRight: 0 }}
                >
                  <Icon name="remove" />
                </RemoveItem>
              </ArrayItem>
            )
          })}
          <div style={{ display: 'flex', justifyContent: 'right' }}>
            <Button
              size="small"
              onClick={() => arrayHelpers.push(pushItem)}
              style={{ marginRight: 0 }}
            >
              <Icon name="add" /> Add {name} item
            </Button>
          </div>
        </div>
      )}
    />
  )
}

const ArrayItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 0.5em;
`

const RemoveItem = styled(Button)`
  margin-left: 0.5em;
`

const BOOLEAN_ARRAY_ITEMS = [
  { label: 'true', value: true },
  { label: 'false', value: false },
]

const BooleanArrayInput = ({ name }) => {
  const { values, setFieldValue } = useFormikContext()

  const formikValue = get(values, name)
  const valueOption = BOOLEAN_ARRAY_ITEMS.find(
    option => option.value === formikValue,
  )

  return (
    <div style={{ width: '100%' }}>
      <Select
        value={valueOption}
        options={BOOLEAN_ARRAY_ITEMS}
        onChange={({ value }) => setFieldValue(name, value)}
      />
    </div>
  )
}

const FormGroup = styled(Form.Input.Group)`
  display: inline-flex;
  align-items: center;
  width: 100%;
`

const FormLabel = styled.label`
  margin-bottom: 0.5em;
`

export default NewResourceModal
