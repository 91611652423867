import { AsyncSelect } from '@waylay/react-components'

import client from '../../lib/client'

export const findTypes = async (filter: string) => {
  const { values: types = [] } = await client.types.list({ filter })
  return types.map(type => ({ ...type, label: type.name, value: type.id }))
}

interface IResourceTypeSelect {
  name?: string
  value?: string
  onChange: Function
  isMulti?: boolean
}

const noOptionsMessage = ({ inputValue }) =>
  inputValue.length
    ? `No resource types found containing '${inputValue}'`
    : 'Start typing…'

const ResourceTypeSelect = (props: IResourceTypeSelect) => {
  const { value, onChange, ...rest } = props

  const loadOptions = async filter => await findTypes(filter)

  return (
    <AsyncSelect
      cacheOptions
      defaultOptions
      isClearable
      defaultValue={getDefaultValue(value)}
      noOptionsMessage={noOptionsMessage}
      loadOptions={loadOptions}
      onChange={v => onChange(v || {})}
      {...rest}
    />
  )
}

const getDefaultValue = value => {
  if (Array.isArray(value)) return value.map(v => ({ label: v, value: v }))
  return value && { label: value, value }
}

export default ResourceTypeSelect
