import React from 'react'
import styled from '@emotion/styled'

export const PaddedContent = styled.div`
  padding: 1em 1.25em;
  flex-grow: 1;
`

export const withPadding = (Component, style = {}) =>
  function Wrapper() {
    return (
      <PaddedContent style={style}>
        <Component />
      </PaddedContent>
    )
  }
