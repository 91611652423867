import { useEffect } from 'react'
import useLocalStorage from '@rehooks/local-storage'

import { WaylayEntity } from '~/lib/types'
import { useLogin } from '~/components/App/LoginContext'

const RECENT_ITEMS_MAX_SIZE = 9
const RECENT_ITEMS_KEY_PREFIX = 'waylay_history'

export interface IRecentItem {
  id: string
  name: string
  type: WaylayEntity
  data?: {
    [key: string]: any
  }
}

export type RecentItems = IRecentItem[]

interface IRecentItemHook {
  recentItems: RecentItems
  addRecentItem: (IRecentItem) => void
}

export const useRecentItems = (): IRecentItemHook => {
  const { tenant } = useLogin()

  const key = createKey(tenant)
  const [recentItems, setRecentItems] = useLocalStorage<RecentItems>(key, [])

  const addRecentItem = (newItem: IRecentItem) => {
    const dedupedItems = recentItems.filter(item => item.id !== newItem.id)

    const newRecentItems = [newItem, ...dedupedItems]

    if (newRecentItems.length > RECENT_ITEMS_MAX_SIZE) {
      newRecentItems.splice(RECENT_ITEMS_MAX_SIZE)
    }

    setRecentItems(newRecentItems)
  }

  return {
    recentItems,
    addRecentItem,
  }
}

const createKey = tenant => `${RECENT_ITEMS_KEY_PREFIX}-${tenant}`

export const RecentItem = ({ id, name, type, data }: IRecentItem): null => {
  if (!name) return null

  const { addRecentItem } = useRecentItems()

  useEffect(() => {
    const newItem = { id, name, type, data }
    addRecentItem(newItem)
  }, [id, name, type])

  return null
}
