import React from 'react'
import { Link, NavLink, useParams } from 'react-router-dom'
import styled from '@emotion/styled'
import { Icon, colors, clearSky, carbon } from '@waylay/react-components'
import { TabsItem } from './Tabs'

export const Name = styled.span`
  font-weight: 500;
`

export const Identifier = styled.span`
  font-size: 0.9em;
  font-weight: normal;
  text-decoration: none;
  color: ${carbon};
`

export const Title = props => {
  const elem = props.to ? styled(Link) : styled.span
  const clickable = props.to || props.onClick

  const comp = elem`
    text-decoration: none;
    color: inherit;
    display: flex;
    align-items: center;
    width: max-content;
    cursor: ${clickable ? 'pointer' : 'inherit'};

    ${Name} {
      margin-right: 0.5em;
      color: ${clickable ? clearSky : 'inherit'};
    }
  `

  const onClick = e => {
    e.stopPropagation()
  }

  return React.createElement(comp, { onClick, ...props })
}

export const Description = styled.div`
  font-size: 0.9rem;
`

export const Meta = styled.div`
  & > * + * {
    margin-left: 0.5em;
  }
`

export const MetaItem = styled.span`
  font-size: 0.8em;
  color: ${carbon};
  text-decoration: none;

  display: inline-flex;
  vertical-align: middle;
  align-items: center;

  white-space: pre;

  cursor: ${props => (props.onClick ? 'pointer' : 'inherit')};

  a {
    color: inherit;
    text-decoration: underline;
    text-decoration-style: dotted;
  }

  a:hover {
    color: ${colors.withWeight(carbon, 700)};
  }

  & i {
    margin-right: 0.3em;
  }
`

export const TabMenu = ({ items = [] }) => {
  const tabs = items.map(({ name, to, icon }) => (
    <TabItem key={to} to={to}>
      {icon && <Icon name={icon} />}
      {name}
    </TabItem>
  ))

  return <nav>{tabs}</nav>
}

const TabItem = ({ children, to }) => {
  const { tab } = useParams<{ [key: string]: string }>()

  const isActive = to.endsWith(tab)

  return (
    <TabsItem active={isActive} as={NavLink} to={to} replace>
      {children}
    </TabsItem>
  )
}
