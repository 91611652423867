import styled from '@emotion/styled'
import { ReactNode } from 'react'

interface IStack {
  spacing?: string | number
  as?: ReactNode
  alignment?: string
}

export const Divider = styled.hr`
  border: 0;
  height: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
`

export const HStack = styled.div<IStack>`
  display: flex;
  flex-direction: row;
  ${({ alignment = 'center' }) => `align-items: ${alignment};`}

  > * {
    ${({ spacing = 0 }) => `margin-left: ${spacing};`}
  }

  > *:first-of-type {
    margin-left: 0;
  }
`

export const VStack = styled.div<IStack>`
  display: flex;
  flex-direction: column;

  > * {
    ${({ spacing = 0 }) => `margin-top: ${spacing};`}
  }

  > *:first-of-type {
    margin-top: 0;
  }
`

export const Spacer = styled.div`
  flex: 1;
`
