import React from 'react'
import { Input } from '@waylay/react-components'
import { OpenApiContainer } from './useUploadOpenApiPlug'
import Box, { BoxType } from './Box'

const UrlInput = () => {
  const { url, setUrl, validationError } = OpenApiContainer.useContainer()

  return (
    <>
      <Input.Group style={{ width: '100%' }}>
        <Input
          data-testid="url-input"
          name="search"
          placeholder="https://petstore3.swagger.io/api/v3/openapi.json"
          fluid
          style={{ width: '100%' }}
          value={url}
          onInput={event => setUrl(event.target.value)}
        />
      </Input.Group>

      {validationError && (
        <Box type={BoxType.error} text={validationError} title="Error" />
      )}
    </>
  )
}

export default UrlInput
