import { useState } from 'react'

interface IMutationHookCallbacks<T, U> {
  onSuccess?: (result: T, args: any[]) => void
  onError?: (err: U, args: any[]) => void
}

interface IMutationHook<U> {
  loading: boolean
  error: U
  execute: Function
  setError: Function
}

const useMutation = <T, U = Error>(
  mutation: Function,
  { onSuccess, onError }: IMutationHookCallbacks<T, U>,
): IMutationHook<U> => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(undefined)

  const execute = async (...args) => {
    setLoading(true)
    setError(undefined)

    let result
    try {
      result = await mutation(...args)
      setLoading(false)
    } catch (err) {
      setLoading(false)
      setError(err)
      typeof onError === 'function' && onError(err, [...args])
      return
    }

    typeof onSuccess === 'function' && onSuccess(result, [...args])
    return result
  }

  return {
    loading,
    error,
    execute,
    setError,
  }
}

export default useMutation
