const importRegex = /^\s*import.*/

export const normalizeTypeDeclarations = (typeDeclarations: string[]) =>
  typeDeclarations
    .map(types => {
      return types
        .split(/\r?\n/)
        .filter((line: string) => !importRegex.test(line))
        .map((line: string) =>
          line
            .replaceAll('export {};', '')
            .replaceAll('export class', 'declare class')
            .replaceAll('export default class', 'declare class')
            .replaceAll(/export default (.*);/g, '')
            .replaceAll('export type', 'type')
            .replaceAll('export interface', 'interface')
            .replaceAll('export declare', 'declare'),
        )
        .join('\r\n')
    })
    .join('\r\n')
