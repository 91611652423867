import React, { useEffect, useMemo, useState } from 'react'
import jwtDecode from 'jwt-decode'
import * as Sentry from '@sentry/browser'
import useLocalStorage from '@rehooks/local-storage'
import { useHistory } from 'react-router-dom'
import { get, isEmpty } from 'lodash-es'

import client from '../../lib/client'
import QueryStringProvider from '../../hooks/useQueryString'
import { IJsonWebTokenBody } from '../../lib/types'

interface ILoginContext {
  token: string
  domain: string
  tenant: string
  sub: string
  isAuthenticated: boolean
  logout: (options: WithRedirectProps) => void
}

interface WithRedirectProps {
  withRedirect: boolean
  errorMessage?: string
}

const { REACT_APP_WAYLAY_IDENTITY_URL = 'https://login-staging.waylay.io/' } =
  window.env

const LOGIN_URL = REACT_APP_WAYLAY_IDENTITY_URL

const LoginContext = React.createContext(null)

const LoginProvider = ({ children }) => {
  const { query } = QueryStringProvider.useContainer()
  const { token: searchToken, refresh_token: searchRefreshToken } = query

  const history = useHistory()
  const [token, setToken, deleteToken] = useLocalStorage('token', null)
  const [refreshToken, setRefreshToken, deleteRefreshToken] = useLocalStorage(
    'refresh_token',
    null,
  )
  const [withRedirect, setWithRedirect] = useState(true)

  const [validToken, tokenErrorMessage = ''] = useMemo(() => {
    const [isTokenValid, tokenErrMsg] = isValidToken(token)
    const [isSearchTokenValid] = isValidToken(searchToken)
    return [isTokenValid || isSearchTokenValid, tokenErrMsg]
  }, [token, searchToken])

  const [validRefreshToken] = useMemo(() => {
    const [isRefreshTokenValid, refreshTokenErr] =
      isValidRefreshToken(refreshToken)
    const [isSearchRefreshTokenValid] = isValidRefreshToken(searchRefreshToken)
    return [isRefreshTokenValid || isSearchRefreshTokenValid, refreshTokenErr]
  }, [refreshToken, searchRefreshToken])

  const [isAuthenticated, setIsAuthenticated] = useState(validToken)

  useEffect(() => {
    if (!token) return // bail when no token is found

    // or if token is malformed
    if (isMalformedToken(token)) {
      logout({ withRedirect: false })
      return
    }

    if (refreshToken && isMalformedToken(refreshToken)) {
      logout({ withRedirect: false })
      return
    }

    const { domain, tenant, sub } = jwtDecode<IJsonWebTokenBody>(token)

    Sentry.configureScope(scope => {
      scope.setTag('tenant', tenant)
      scope.setTag('domain', domain)
      scope.setUser({ id: sub })
    })

    setIsAuthenticated(isValidToken(token)[0])
  }, [token])

  // @ts-expect-error tenant and sub cannot be destructured from client
  const { domain, tenant, sub } = useMemo(
    () => (token ? jwtDecode(token) : client),
    [token],
  )

  const logout = ({ withRedirect = true }: WithRedirectProps) => {
    Sentry.configureScope(scope => scope.setUser(null))
    deleteToken()
    deleteRefreshToken()
    setIsAuthenticated(false)
    setWithRedirect(withRedirect)
  }

  const updateToken = (token: string, refreshToken?: string) => {
    setToken(token)
    client.setToken(token)

    if (refreshToken) {
      setRefreshToken(refreshToken)
      client.setRefreshToken(refreshToken)
    }
  }

  // if we received a token via the URL, check if it is valid and remove it with
  // history.replace()
  useEffect(() => {
    if (!searchToken) return // early return when we don't have one

    updateToken(searchToken, searchRefreshToken)

    const { pathname, search } = new URL(stripTokenParams())
    const redirectUrl = pathname + search

    history.replace(redirectUrl)
  }, [searchToken, searchRefreshToken])

  // whenever we change to not being authenticated, we redirect the user to the login service
  useEffect(() => {
    // no valid refresh and regular token
    if (!validToken && !validRefreshToken) {
      goToIdentityService({ withRedirect, errorMessage: tokenErrorMessage })
    }
    // try and refresh expired token
    if (!validToken && validRefreshToken) {
      client.tokens.refresh()
    }
  }, [token, searchToken, refreshToken, searchRefreshToken])

  function isValidToken(token?: string): [boolean, string] {
    if (!token) return [false, '']

    try {
      const { exp, permissions } = jwtDecode<IJsonWebTokenBody>(token)
      const expiresInFuture = isFuture(new Date(exp * 1000))
      if (!expiresInFuture) return [false, 'token has expired']
      const hasPermissions = !isEmpty(permissions)
      if (!hasPermissions) {
        // delete the refresh token because else we get in refresh loop
        deleteRefreshToken()
        return [false, 'user has no permissions']
      }

      return [true, '']
    } catch (err) {
      return [false, 'invalid token format']
    }
  }

  return (
    <LoginContext.Provider
      value={{ token, domain, tenant, sub, isAuthenticated, logout }}
    >
      {children}
    </LoginContext.Provider>
  )
}

const useLogin = (): ILoginContext => React.useContext(LoginContext)

function isValidRefreshToken(token?: string): [boolean, string] {
  if (!token) return [false, '']

  try {
    const { exp } = jwtDecode<IJsonWebTokenBody>(token)
    const expiresInFuture = isFuture(new Date(exp * 1000))
    if (!expiresInFuture) return [false, 'refresh token has expired']

    return [true, '']
  } catch (err) {
    return [false, 'invalid refresh token format']
  }
}

function isMalformedToken(token: string): boolean {
  try {
    jwtDecode(token)
    return false
  } catch (err) {
    return true
  }
}

function isFuture(date: Date): boolean {
  return new Date() < date
}

function getRedirectUrl() {
  return stripTokenParams()
}

function stripTokenParams() {
  const baseUrl = href()
  return removeParam(removeParam(baseUrl, 'refresh_token'), 'token')
}

export function goToIdentityService({
  withRedirect = true,
  errorMessage,
}: WithRedirectProps) {
  const url = new URL('login', LOGIN_URL)

  const redirect = withRedirect
    ? getRedirectUrl() // redirect url includes current page of the session
    : window.location.origin // base console url
  url.searchParams.set('redirect_uri', redirect)

  if (errorMessage) url.searchParams.set('error', errorMessage)

  window.location.assign(url.href)
}

function removeParam(url: string, key: string) {
  const parsed = new URL(url)
  parsed.searchParams.delete(key)
  return parsed.toString()
}

function href(): string {
  return get(window, ['location', 'href'])
}

export { LoginProvider, useLogin }
