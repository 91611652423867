import React from 'react'
import {
  Button,
  Segment,
  Icon,
  Popup,
  List,
  colors,
  concrete,
  Toggle,
} from '@waylay/react-components'
import styled from '@emotion/styled'
import { EDITOR_SETTINGS_MAP } from '.'
import { css } from '@emotion/core'

const StatusBar = styled.div<{ vimMode: boolean }>`
  display: flex;
  align-items: center;
  background-color: ${props =>
    props.vimMode ? colors.withWeight(concrete, 100) : ''};

  width: 100%;

  padding: 0.25rem 0.5rem;
  border-top: solid 1px ${concrete};
  position: relative;
`

const VimStatusLine = styled.div`
  span {
    padding-left: 1em;
  }
`

const EditorStatusBar = props => {
  const {
    vimStatusLineRef,
    editorSettings,
    toggleSetting,
    supportedLanguages,
    selectedLanguage,
    setSelectedLanguage,
  } = props

  return (
    <StatusBar vimMode={editorSettings.useVimMode}>
      <Popup
        content={
          <Segment.Group>
            <Segment.Header>
              <Icon name="settings" /> Editor Settings
            </Segment.Header>
            <List as={Segment} padding={0} interactive>
              {Object.keys(editorSettings).map(settingName => (
                <List.Item
                  key={settingName}
                  onClick={() => toggleSetting(settingName)}
                >
                  <Toggle
                    checked={editorSettings[settingName]}
                    onClick={() => toggleSetting(settingName)}
                  />
                  {EDITOR_SETTINGS_MAP[settingName]}
                </List.Item>
              ))}
            </List>
          </Segment.Group>
        }
      >
        <Button size="small" kind="secondary">
          <Icon name="settings" />
        </Button>
      </Popup>

      <LanguageSelect
        supportedLanguages={supportedLanguages}
        selectedLanguage={selectedLanguage}
        setSelectedLanguage={setSelectedLanguage}
      />
      <VimStatusLine ref={vimStatusLineRef} />
    </StatusBar>
  )
}

const LanguageSelect = ({
  supportedLanguages,
  selectedLanguage,
  setSelectedLanguage,
}) => {
  const canSelectLanguage = supportedLanguages.length > 1

  return (
    <div
      css={css`
        margin-left: 5px;
      `}
    >
      {canSelectLanguage ? (
        <Popup
          content={
            <Segment.Group>
              <Segment.Header>
                <Icon name="language" /> <span>Language</span>
              </Segment.Header>
              <List as={Segment} padding={0} interactive>
                {supportedLanguages.map(lang => (
                  <List.Item
                    key={lang}
                    onClick={() => setSelectedLanguage(lang)}
                  >
                    <span>{lang}</span>
                  </List.Item>
                ))}
              </List>
            </Segment.Group>
          }
        >
          <Button size="small" kind="secondary">
            <span>{selectedLanguage}</span>
          </Button>
        </Popup>
      ) : (
        <span>{selectedLanguage}</span>
      )}
    </div>
  )
}

export default EditorStatusBar
