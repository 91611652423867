import semver from 'semver'
import { find, uniqBy, pick, last } from 'lodash-es'

export interface IPlug {
  name: string
  version: string
  type: 'sensor' | 'actuator'
}

export interface IAvailablePlugUpgrade extends IPlug {
  newerVersion: string
  enabled?: boolean
}

export function findPlugUpdates(
  plugsInUse = [],
  latestPlugs = [],
): IAvailablePlugUpgrade[] {
  return plugsInUse.reduce((acc, plug) => {
    const { name, version: currentVersion } = plug

    const latestPlug = find(latestPlugs, { name })

    if (!latestPlug) return acc
    const hasNewer = semver.gt(latestPlug.version, currentVersion)

    const canBeUpdated = latestPlug && hasNewer
    return canBeUpdated
      ? acc.concat({
          name,
          version: currentVersion,
          newerVersion: latestPlug.version,
          type: plug.type,
        })
      : acc
  }, [])
}

export function detectMissingPlugs(plugsInUse = [], latestPlugs = []): IPlug[] {
  return plugsInUse.reduce((acc, plug) => {
    const plugExists = find(latestPlugs, { name: plug.name })

    return !plugExists ? acc.concat(plug) : acc
  }, [])
}

export function semverNewer(current: string, versions: string[]) {
  return versions.filter(version => semver.gt(version, current))
}

export function bestSemverMatch(current: string, versions: string[]) {
  const newer = semverNewer(current, versions)
  const bestSemverMatch = semver.maxSatisfying(newer, '^' + current)

  return bestSemverMatch || last(versions)
}

export function uniquePlugsFromNetwork(nodes = []) {
  // omit gates etc
  const plugs = nodes.reduce((acc, n) => {
    if (n.properties.sensor) {
      acc.push({
        ...n.properties.sensor,
        type: 'sensor',
      })
    }

    if (n.properties.actions) {
      acc = acc.concat(
        n.properties.actions.map(a => {
          return {
            ...pick(a, ['name', 'version']),
            type: 'actuator',
          }
        }),
      )
    }

    return acc
  }, [])

  // unique by name and version
  return uniqBy(plugs, (n: IPlug) => n.name + n.version)
}
