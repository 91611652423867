import React from 'react'

import { Toggle } from '@waylay/react-components'
import { IPropertyInput } from '../PropertyInput'

const StringField = ({ property, value, setFieldValue }: IPropertyInput) => {
  const { name, defaultValue } = property

  const handleChange = event => {
    setFieldValue(name, event.target.checked)
  }

  return (
    <Toggle
      checked={value === undefined ? defaultValue : value}
      onChange={handleChange}
    />
  )
}

export default StringField
