import 'react-hot-loader'
import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/browser'
import { patchAccessibilityStyles } from '@waylay/react-components'
import pjson from '../package.json'

import App from './components/App/App'

// Import all assets
import './lib/asset-import'

const {
  REACT_APP_VERSION,
  REACT_APP_SENTRY_DSN,
  REACT_APP_SENTRY_ENVIRONMENT = 'local',
} = window.env

// This is the React root element
const root = document.getElementById('root')

// This styles container will be used by the Emotion CacheProvider
const styles = document.getElementById('emotion')

// Patch browser a11y outline styles
patchAccessibilityStyles()

// initialize the Sentry client
Sentry.init({
  dsn: REACT_APP_SENTRY_DSN,
  environment: REACT_APP_SENTRY_ENVIRONMENT,
  release: pjson.version,
  autoSessionTracking: false,
})

Sentry.setTag('git.version', REACT_APP_VERSION)

ReactDOM.render(<App stylesContainer={styles} />, root)
