import { useEffect } from 'react'
import useOnlineStatus from '@rehooks/online-status'
import { useToasts } from 'react-toast-notifications'
import { useDelta } from 'react-delta'
import { isUndefined } from 'lodash-es'

const useOnlineOffline = () => {
  const online = useOnlineStatus()
  const delta = useDelta(online)
  const { addToast } = useToasts()

  useEffect(() => {
    if (!delta) return

    // if we have no previous value, we don't care
    if (isUndefined(delta.prev)) return

    // we are going from online to offline
    if (delta.prev && !delta.curr) {
      addToast('You are now working offline', { appearance: 'warning' })
    }

    // we are going from offline to online
    if (!delta.prev && delta.curr) {
      addToast('You are now connected again', { appearance: 'success' })
    }
  }, [online])
}

export default useOnlineOffline
