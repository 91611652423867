import { useAsync } from 'react-async'
import { createContainer } from 'unstated-next'

import client from '../../../lib/client'

const fetchProfile = async () => {
  const { fullname, firstname, email, tenant, keys, timezone } =
    await client.account.get()

  return {
    email,
    firstName: firstname,
    fullName: fullname,
    timezone,
    apiKeys: keys.map(
      ({ id, client_id: apiKey, client_secret: apiSecret, description }) => ({
        id,
        apiKey,
        apiSecret,
        description,
      }),
    ),
    organisation: tenant.name,
  }
}

export function useProfile() {
  return useAsync(fetchProfile)
}

const ProfileContainer = createContainer(useProfile)

export default ProfileContainer
