import React from 'react'
import { Button } from '@waylay/react-components'
import UrlInput from './URLInput'
import { ImportType, OpenApiContainer } from './useUploadOpenApiPlug'
import FileImport from '~/components/Common/FileImport'

const Upload = () => {
  const {
    importType,
    setImportType,
    setFile,
    setValidationError,
    validationError,
    fileName,
    setFileName,
  } = OpenApiContainer.useContainer()

  const onSuccess = (file: string, fileName: string) => {
    setFile(file)
    setFileName(fileName)
  }

  return (
    <div style={{ padding: 10 }}>
      <Button.Group style={{ marginBottom: '15px' }}>
        <Button
          data-testid="button-file"
          kind="primary"
          outline={!(importType === ImportType.File)}
          onClick={() => setImportType(ImportType.File)}
        >
          File
        </Button>
        <Button
          data-testid="button-url"
          kind="primary"
          outline={!(importType === ImportType.Url)}
          onClick={() => setImportType(ImportType.Url)}
        >
          URL
        </Button>
      </Button.Group>
      {importType === ImportType.Url ? (
        <UrlInput />
      ) : (
        <FileImport
          setError={setValidationError}
          fileName={fileName}
          error={validationError}
          onSuccess={onSuccess}
          formats="OpenAPI 3.0"
          isOpenApi
        />
      )}
    </div>
  )
}

export default Upload
