import React, { useState } from 'react'
import { DateTime } from 'luxon'
import styled from '@emotion/styled'
import { Icon, carbon } from '@waylay/react-components'

export const Timestamp = styled.span`
  margin-left: 0.5em;
  font-size: 0.8rem;
  color: ${carbon};
  vertical-align: middle;

  display: inline-flex;
  white-space: pre;
  align-items: center;
`

export const RelativeTimestamp = ({ timestamp, text = '' }) => {
  const [human, setHuman] = useState(false)

  if (!timestamp) return <TimestampComponent text={text} timestamp="never" />

  const datetime = DateTime.fromISO(timestamp)
  const humanTimestamp = datetime.toLocaleString(DateTime.DATETIME_SHORT)
  const relativeTimestamp = datetime.toRelative()

  const toggleFormat = () => setHuman(human => !human)

  return (
    <TimestampComponent
      timestamp={human ? humanTimestamp : relativeTimestamp}
      title={humanTimestamp}
      onClick={() => toggleFormat()}
      text={text}
    />
  )
}

export const AbsoluteTimestamp = ({ text = '', timestamp }) => {
  const datetime = DateTime.fromISO(timestamp)
  const humanTimestamp = datetime.toLocaleString(DateTime.DATETIME_SHORT)

  return <TimestampComponent text={text} timestamp={humanTimestamp} />
}

interface ITimestampProps {
  timestamp: string
  title?: string
  onClick?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => any
  text?: string
}

const TimestampComponent = ({
  timestamp,
  title,
  onClick,
  text,
}: ITimestampProps) => (
  <Timestamp title={title} onClick={onClick}>
    <Icon name="access_time" /> {text} {timestamp}
  </Timestamp>
)
