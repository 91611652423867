import { createContainer } from 'unstated-next'
import { useToasts } from 'react-toast-notifications'
import useSWR, { mutate, trigger } from 'swr'

import { ISetting } from '~/lib/types'
import useMutation from '../../../../hooks/useMutation'
import client from '../../../../lib/client'

const VAULT = 'vault'

const fetchVaultKeys = (): Promise<ISetting[]> => {
  return client.vault.keys() as Promise<ISetting[]>
}

const fetchVaultSetting = (key): Promise<ISetting> => {
  return client.vault.get(key) as Promise<ISetting>
}

const reload = async () => await trigger(VAULT)

export const useVault = () => {
  const { data, error } = useSWR(VAULT, fetchVaultKeys, {
    revalidateOnFocus: false,
  })

  const loading = !data && !error

  return { data, error, loading }
}

export const useAddVaultKey = () => {
  const { data } = VaultContainer.useContainer()
  const { addToast } = useToasts()

  return useMutation(
    async (key, value) => {
      await client.vault.set(key, value)
      return { key, value }
    },
    {
      onSuccess: ({ key }) => {
        const index = data.findIndex(pair => pair.key === key)
        const clone = [...data]

        index === -1 ? clone.push({ key }) : (clone[index] = { key })

        mutate(VAULT, clone, false)
        addToast('Successfully saved setting', { appearance: 'success' })
      },
      onError: () => {
        addToast('Failed to save setting', { appearance: 'error' })
      },
    },
  )
}

export const useRemoveVaultKey = () => {
  const { addToast } = useToasts()

  return useMutation(
    async key => {
      await client.vault.remove(key)
      return { key }
    },
    {
      onSuccess: () => {
        reload()
        addToast('Successfully removed setting', { appearance: 'success' })
      },
      onError: () => {
        addToast('Failed to remove setting', { appearance: 'error' })
      },
    },
  )
}

export const useRevealVaultValue = () => {
  const { data } = VaultContainer.useContainer()

  return useMutation(
    async key => {
      const value = await fetchVaultSetting(key)
      return {
        key,
        value: typeof value === 'string' ? value : JSON.stringify(value),
      }
    },
    {
      onSuccess: ({ key, value }) => {
        const newData = data.map(item => {
          return item.key === key ? { ...item, value } : item
        })
        mutate(VAULT, newData, false)
      },
    },
  )
}

export default useVault
export const VaultContainer = createContainer(useVault)
