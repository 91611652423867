/* eslint-disable @typescript-eslint/return-await */

export async function retryImport(
  importFn: () => Promise<any>,
  retries = 10,
  interval = 1000,
): Promise<any> {
  try {
    return await importFn()
  } catch (error) {
    if (retries) {
      await wait(interval)
      return retryImport(importFn, retries - 1, interval)
    } else {
      throw error
    }
  }
}

export async function wait(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export async function timeout(promise: Promise<any>, ms: number) {
  return await Promise.race([
    promise,
    new Promise((resolve, reject) =>
      setTimeout(() => {
        reject(new TimeoutError())
      }, ms),
    ),
  ])
}

export async function retryWithPredicate(
  operation: () => Promise<any>,
  predicate: (...args) => boolean,
  delay: number,
  retries = Infinity,
) {
  return new Promise((resolve, reject) => {
    return operation()
      .then(result => {
        if (!predicate(result)) {
          if (retries === 0) {
            throw new Error('Retries exceeded')
          }
          return wait(delay)
            .then(
              retryWithPredicate.bind(
                null,
                operation,
                predicate,
                delay,
                retries - 1,
              ),
            )
            .then(resolve)
            .catch(reject)
        }
        return resolve(result)
      })
      .catch(reject)
  })
}

export class TimeoutError extends Error {}
