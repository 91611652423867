import { css } from '@emotion/core'
import { controls } from '../Common/GraphEditor.styles'

export default css`
  background-color: #fcfdfd;
  background-image: url('data:image/svg+xml,%3Csvg class="dm-dots" xmlns="http://www.w3.org/2000/svg"%0A%3E%3Cdefs%3E%3Cpattern id="dm-dot-pattern" width="10" height="10" fill="%23EEF0F0" patternUnits="userSpaceOnUse" %3E%3Cpath d="M0,0 m-1,0 a1,1 0 1,1 2,0 a1,1 0 1,1 -2,0"%3E%3C/path%3E%3Cpath d="M10,0 m-1,0 a1,1 0 1,1 2,0 a1,1 0 1,1 -2,0"%3E%3C/path%3E%3Cpath d="M10,10 m-1,0 a1,1 0 1,1 2,0 a1,1 0 1,1 -2,0"%3E%3C/path%3E%3Cpath d="M0,10 m-1,0 a1,1 0 1,1 2,0 a1,1 0 1,1 -2,0"%3E%3C/path%3E%3C/pattern%3E%3C/defs%3E%3Crect fill="url(%23dm-dot-pattern)" width="100%25" height="100%25" %3E%3C/rect%3E%3C/svg%3E');

  .graph-editor__root {
    position: relative;
    outline: none;
  }

  ${controls};
`
