import React, { useCallback, useRef } from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { ErrorMessage, Formik } from 'formik'
import * as Yup from 'yup'
import {
  Button,
  Form,
  Icon,
  Popup,
  Segment,
  List,
  carbon,
  concrete,
  watermelon,
} from '@waylay/react-components'
import { Duration } from 'luxon'
import { get } from 'lodash-es'

import { SmallLabel } from '~/components/Explore/Form'
import { Grouping } from './Editor/DataQueriesTypes'

interface IGroupingOption {
  label: string
  value: Grouping
}

const groupingOptions: IGroupingOption[] = [
  { label: 'None', value: Grouping.None },
  { label: '1 minute', value: Grouping.Minute },
  { label: '15 minutes', value: Grouping.FifteenMinutes },
  { label: '1 hour', value: Grouping.Hour },
  { label: '8 hours', value: Grouping.EightHours },
  { label: '24 hours', value: Grouping.TwentyFourHours },
]

interface ITimeWindowProps {
  onChange: Function
  grouping?: string
}

const validGrouping = Yup.string().test(
  'valid-grouping',
  'Grouping is not a valid ISO Duration',
  (value: string) => {
    return Duration.fromISO(value).isValid
  },
)

const GroupingPicker = ({ onChange, grouping }: ITimeWindowProps) => {
  const popup = useRef(null)

  const existingGroupingOption = groupingOptions.find(
    ({ value }) => value === grouping,
  )
  const groupingText = get(existingGroupingOption, 'label', grouping)

  const setGrouping = useCallback(({ grouping }) => {
    onChange(grouping)

    if (popup.current) popup.current.hide()
  }, [])

  const validationSchema = Yup.object().shape({ grouping: validGrouping })
  const intialValues = { grouping: grouping ?? Grouping.None }

  return (
    <Popup
      onCreate={instance => {
        popup.current = instance
      }}
      maxWidth="auto"
      placement="bottom-end"
      content={
        <Segment padding={0}>
          <TwoColumns>
            <Column>
              <ListHeader>Custom</ListHeader>
              <AbsoluteGrouping>
                <Formik
                  onSubmit={setGrouping}
                  validationSchema={validationSchema}
                  initialValues={intialValues}
                  enableReinitialize
                >
                  {({ handleChange, handleSubmit, values }) => (
                    <Form onSubmit={handleSubmit}>
                      <GridFields>
                        <div>
                          <SmallLabel htmlFor="grouping">Grouping</SmallLabel>
                          <Form.Input.Group fluid>
                            <Form.Input
                              id="grouping"
                              name="grouping"
                              onChange={handleChange}
                              value={values.grouping}
                            />
                          </Form.Input.Group>
                          <ErrorMessage name="grouping">
                            {message => (
                              <SmallErrorMessage>{message}</SmallErrorMessage>
                            )}
                          </ErrorMessage>
                        </div>
                      </GridFields>
                      <Button type="submit" kind="primary">
                        Apply
                      </Button>
                    </Form>
                  )}
                </Formik>
              </AbsoluteGrouping>
            </Column>
            <Column
              css={css`
                border-left: solid 1px ${concrete};
              `}
            >
              <ListHeader>Quick selections</ListHeader>
              <GroupingList interactive>
                {groupingOptions.map(({ value, label }) => (
                  <List.Item
                    key={value}
                    active={false}
                    onClick={() => setGrouping({ grouping: value })}
                  >
                    {label}
                  </List.Item>
                ))}
              </GroupingList>
            </Column>
          </TwoColumns>
        </Segment>
      }
    >
      <Button kind="secondary" outline>
        Grouping: &nbsp;{groupingText}&nbsp;
        <Icon name="keyboard_arrow_down" />
      </Button>
    </Popup>
  )
}

const GridFields = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  grid-row-gap: 0.5rem;

  margin-bottom: 0.5rem;
`

const Column = styled.div`
  overflow-y: auto;
`

const ListHeader = styled.header`
  font-size: 0.75rem;
  color: ${carbon};
  padding-top: 0.75rem;
  padding-right: 1rem;
  padding-bottom: 0.1rem;
  padding-left: 1rem;
`

const TwoColumns = styled.div`
  display: flex;
  flex-direction: row;
  max-height: 70vh;
`

const AbsoluteGrouping = styled.div`
  width: 225px;
  padding: 0.5rem 1rem;
`

const GroupingList = styled(List)`
  line-height: 1.25rem;
`

const SmallErrorMessage = styled.span`
  font-size: 0.9em;
  color: ${watermelon};
`

export default GroupingPicker
