import React, { useCallback, useRef, useState } from 'react'
import { hot } from 'react-hot-loader/root'
import { Settings } from 'luxon'
import { BrowserRouter, Route } from 'react-router-dom'
import { useModal } from 'react-modal-hook'
import { css } from '@emotion/core'
import { Modal, Button, Segment } from '@waylay/react-components'
import { enableMapSet } from 'immer'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import Context from './Context'
import { LoginProvider } from './LoginContext'
import Dashboard from '../Dashboard/Dashboard'
import QueryString from '../../hooks/useQueryString'
import SidebarContainer from '../Dashboard/Sidebar/useCollapsed'
import Branding, { Head as BrandingHead } from './Branding'
import 'semantic-ui-css/semantic.min.css'
import { SimulatorContainer } from '../Simulator/useSimulator'
import RunningSimulator from './RunningSimulator'

// enable ES2015 Map and Set
// see https://immerjs.github.io/immer/installation/
enableMapSet()

const App = ({ stylesContainer }) => {
  // set the default locale to en-US for consistent locale strings
  Settings.defaultLocale = 'en-US'

  return (
    <React.StrictMode>
      <Context stylesContainer={stylesContainer}>
        <DndProvider backend={HTML5Backend}>
          <BrowserRouterWithConfirmation>
            <QueryString.Provider>
              <LoginProvider>
                <Branding.Provider>
                  <SimulatorContainer.Provider>
                    <BrandingHead />
                    <RunningSimulator />
                    <SidebarContainer.Provider>
                      <Route component={Dashboard} />
                    </SidebarContainer.Provider>
                  </SimulatorContainer.Provider>
                </Branding.Provider>
              </LoginProvider>
            </QueryString.Provider>
          </BrowserRouterWithConfirmation>
        </DndProvider>
      </Context>
    </React.StrictMode>
  )
}

type ConfirmFn = (ok: boolean) => void

const BrowserRouterWithConfirmation = ({ children }) => {
  // we have to use this ref because getUserConfirmation uses a callback function
  const noop: ConfirmFn = () => {}
  const confirmRef = useRef(noop)
  const [message, setMessage] = useState('')

  const confirm = useCallback(() => {
    confirmRef.current(true)
    hideModal()
  }, [confirmRef])

  const [showModal, hideModal] = useModal(
    () => (
      <Modal isOpen onRequestClose={hideModal}>
        <Segment.Group
          css={css`
            width: 600px;
          `}
        >
          <Segment.Header>Confirm navigation</Segment.Header>
          <Segment>
            {message !== ''
              ? message
              : 'Are you sure you want to leave this page? Any unsaved progress will be lost'}
          </Segment>
          <Modal.Actions>
            <Button outline kind="secondary" onClick={hideModal}>
              Cancel
            </Button>
            <Button kind="primary" autoFocus onClick={() => confirm()}>
              Confirm
            </Button>
          </Modal.Actions>
        </Segment.Group>
      </Modal>
    ),
    [confirm, message],
  )

  return (
    <BrowserRouter
      getUserConfirmation={(_msg, callback) => {
        setMessage(_msg)
        confirmRef.current = callback
        showModal()
      }}
    >
      {children}
    </BrowserRouter>
  )
}

export default hot(App)
