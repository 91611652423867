import { Icon, List } from '@waylay/react-components'
import { useToasts } from 'react-toast-notifications'

import useFileSelector from '~/hooks/useFileSelector'
import usePlug from './usePlug'

function UploadPlug() {
  const { upload } = usePlug()
  const { addToast } = useToasts()

  const handleFileUpload = (file: string) => {
    try {
      const data = JSON.parse(file)
      const plugType = `${data.type}s`
      upload.run(plugType, data)
    } catch (err) {
      addToast('Failed to upload plug: plug is not valid JSON', {
        appearance: 'error',
      })
    }
  }

  const { open } = useFileSelector(handleFileUpload, {
    accept: 'application/json,.json',
  })

  return (
    <List.Item onClick={open}>
      {!upload.isLoading && <Icon name="cloud_upload" />} Upload plug
    </List.Item>
  )
}

export default UploadPlug
