import React, { useEffect, useState } from 'react'
import { useModal } from 'react-modal-hook'
import { css } from '@emotion/core'
import { Modal, Button, Segment, Icon } from '@waylay/react-components'
import { SimulatorContainer, SimulatorStatus } from '../Simulator/useSimulator'

const RunningSimulators = () => {
  const { isSimulatorOpen, simulators, revert } =
    SimulatorContainer.useContainer()
  const [timeoutId, setTimeoutId] = useState(null)

  useEffect(() => {
    const activeSimulators = simulators.find(
      simulator => simulator.status === SimulatorStatus.running,
    )
    if (!isSimulatorOpen && activeSimulators) {
      const id = setTimeout(() => {
        showModal()
      }, 15 * 60 * 1000)
      setTimeoutId(id)
    } else {
      clearTimeout(timeoutId)
    }
  }, [isSimulatorOpen])

  const [showModal, hideModal] = useModal(
    () => (
      <Modal isOpen onRequestClose={hideModal}>
        <Segment.Group
          css={css`
            width: 400px;
          `}
        >
          <Segment.Header>
            <Icon
              name="priority_high"
              filled
              circular
              color="blue"
              style={{ fontSize: 11, marginRight: '2px' }}
            />{' '}
            Information
          </Segment.Header>
          <Segment>
            Simulators are still running. Do you want to stop them?
          </Segment>
          <Modal.Actions>
            <Button
              outline
              kind="secondary"
              onClick={() => {
                const id = setTimeout(() => {
                  showModal()
                }, 15 * 60 * 1000)
                setTimeoutId(id)
                hideModal()
              }}
            >
              Cancel
            </Button>
            <Button
              kind="primary"
              autoFocus
              onClick={() => {
                revert()
                hideModal()
              }}
            >
              Confirm
            </Button>
          </Modal.Actions>
        </Segment.Group>
      </Modal>
    ),
    [simulators],
  )

  return null
}

export default RunningSimulators
