import React from 'react'
import { hot } from 'react-hot-loader/root'
import styled from '@emotion/styled'
import { Link, Switch, Route, useParams } from 'react-router-dom'
import {
  Button,
  Breadcrumb,
  Icon,
  concrete,
  Popup,
} from '@waylay/react-components'

import { Title } from '~/components/Common/Topbar'
import NewPluginModal from '~/components/Plugins/NewModal'
import createResourceModal from '~/components/Resources/New'
import createResourceTypeModal from '~/components/ResourceTypes/New'
import createResourceConstraintModal from '~/components/ResourceConstraints/New'
import QueryStringProvider from '~/hooks/useQueryString'
import useTaskFormModal from '~/components/Tasks/Detail/FormModal'
import PlugActionsList from '~/components/Plugins/PlugActionsList'

export const Container = styled.div`
  padding: 1em 2em;
  background: white;
  border-bottom: solid 1px ${concrete};
  display: flex;
  flex-direction: column;
`

export const Flexed = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  min-height: 32px;
`

export const FlexedRow = styled(Flexed)`
  flex-direction: column;
  align-items: start;
`

export const TextLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`

const AlarmDetail = () => {
  const { id } = useParams<any>()

  return (
    <Container>
      <Flexed>
        <Breadcrumb
          sections={[
            {
              content: <TextLink to="/alarms">Alarms</TextLink>,
              key: 'alarms',
            },
            { content: id, key: 'id' },
          ]}
        />
      </Flexed>
    </Container>
  )
}

const IntegrationsDetail = () => (
  <Container>
    <FlexedRow>
      <Title>Data ingestion</Title>
    </FlexedRow>
  </Container>
)

const AlarmTableView = () => {
  return (
    <Container>
      <FlexedRow>
        <Title>Alarms</Title>
      </FlexedRow>
    </Container>
  )
}

const IntegrationDetail = () => {
  const { pubsubName } = useParams<any>()

  return (
    <Container>
      <Flexed>
        <Breadcrumb
          sections={[
            {
              content: (
                <TextLink to="/administration/settings/organisation/integrations">
                  Integrations
                </TextLink>
              ),
              key: 'integrations',
            },
            { content: pubsubName, key: 'id' },
          ]}
        />
      </Flexed>
    </Container>
  )
}

const PlugDetail = () => {
  const { type, name } = useParams<any>()

  return (
    <Container>
      <Flexed>
        <Breadcrumb
          sections={[
            { content: <TextLink to="/plugins">Plugins</TextLink>, key: type },
            { content: name, key: 'name' },
          ]}
        />
      </Flexed>
    </Container>
  )
}

const PlugNew = () => {
  const { query } = QueryStringProvider.useContainer()
  const { type } = query

  return (
    <Container>
      <Flexed>
        <Breadcrumb
          sections={[
            { content: <TextLink to="/plugins">Plugins</TextLink>, key: type },
            { content: 'New', key: 'name' },
          ]}
        />
      </Flexed>
    </Container>
  )
}

const WebscriptNew = () => {
  const { query } = QueryStringProvider.useContainer()
  const { type } = query

  return (
    <Container>
      <Flexed>
        <Breadcrumb
          sections={[
            {
              content: <TextLink to="/webscripts">Webscripts</TextLink>,
              key: type,
            },
            { content: 'New', key: 'name' },
          ]}
        />
      </Flexed>
    </Container>
  )
}

const WebscriptDetail = () => {
  const { name } = useParams<any>()

  return (
    <Container>
      <Flexed>
        <Breadcrumb
          sections={[
            {
              content: <TextLink to="/webscripts">Webscripts</TextLink>,
              key: 'webscripts',
            },
            { content: decodeURIComponent(name), key: 'name' },
          ]}
        />
      </Flexed>
    </Container>
  )
}

const WebscriptLogView = () => {
  const { name } = useParams<any>()

  return (
    <Container>
      <Flexed>
        <Breadcrumb
          sections={[
            {
              content: <TextLink to="/webscripts">Webscripts</TextLink>,
              key: 'webscripts',
            },
            {
              content: (
                <TextLink to={`/webscripts/${name}`}>
                  {decodeURIComponent(name)}
                </TextLink>
              ),
              key: 'name',
            },
            { content: 'Logs', key: 'logs' },
          ]}
        />
      </Flexed>
    </Container>
  )
}

const PlugList = () => {
  return (
    <Container>
      <Flexed>
        <FlexedRow>
          <Title>Plugins</Title>
        </FlexedRow>
        <Button.Group>
          <NewPluginModal>
            {({ showModal }) => (
              <Button onClick={showModal}>
                <Icon name="add_circle_outline" /> Add plug
              </Button>
            )}
          </NewPluginModal>

          <Popup
            placement="bottom-end"
            appendTo={() => document.body}
            content={<PlugActionsList />}
          >
            <Button>
              <Icon name="keyboard_arrow_down" />
            </Button>
          </Popup>
        </Button.Group>
      </Flexed>
    </Container>
  )
}

const ResourceList = () => {
  const { showModal } = createResourceModal()

  return (
    <Container>
      <Flexed>
        <FlexedRow>
          <Title>Resources</Title>
        </FlexedRow>
        <Button onClick={showModal}>
          <Icon name="add_circle_outline" /> Add resource
        </Button>
      </Flexed>
    </Container>
  )
}

const ResourceDetail = () => {
  const { id } = useParams<any>()

  return (
    <Container>
      <Flexed>
        <Breadcrumb
          sections={[
            {
              content: <TextLink to="/resources">Resources</TextLink>,
              key: 'resources',
            },
            { content: decodeURIComponent(id), key: 'id' },
          ]}
        />
      </Flexed>
    </Container>
  )
}

const ResourceTypes = () => {
  const { showModal } = createResourceTypeModal()
  return (
    <Container>
      <Flexed>
        <FlexedRow>
          <Title>Resource types</Title>
        </FlexedRow>
        <Button onClick={showModal}>
          <Icon name="add_circle_outline" /> Add resource type
        </Button>
      </Flexed>
    </Container>
  )
}

const ResourceTypeDetail = () => {
  const { id } = useParams<any>()

  return (
    <Container>
      <Flexed>
        <Breadcrumb
          sections={[
            {
              content: <TextLink to="/resourcetypes">Resource types</TextLink>,
              key: 'resourcetypes',
            },
            { content: id, key: 'id' },
          ]}
        />
      </Flexed>
    </Container>
  )
}

const ResourceConstraints = () => {
  const { showModal } = createResourceConstraintModal()
  return (
    <Container>
      <Flexed>
        <FlexedRow>
          <Title>Constraints</Title>
        </FlexedRow>
        <Button onClick={showModal}>
          <Icon name="add_circle_outline" /> Add constraint
        </Button>
      </Flexed>
    </Container>
  )
}

const ResourceConstraintDetail = () => {
  const { id } = useParams<{ [key: string]: string }>()

  return (
    <Container>
      <Flexed>
        <Breadcrumb
          sections={[
            {
              content: (
                <TextLink to="/resourceconstraints">Constraints</TextLink>
              ),
              key: 'constraints',
            },
            { content: id, key: 'id' },
          ]}
        />
      </Flexed>
    </Container>
  )
}

const TaskList = () => {
  const { showModal } = useTaskFormModal({})

  return (
    <Container>
      <Flexed>
        <FlexedRow>
          <Title>Tasks</Title>
        </FlexedRow>
        <Button onClick={showModal}>
          <Icon name="add_circle_outline" /> Add task
        </Button>
      </Flexed>
    </Container>
  )
}

const TaskDetail = () => {
  const { id } = useParams<any>()

  return (
    <Container>
      <Flexed>
        <Breadcrumb
          sections={[
            { content: <TextLink to="/tasks">Tasks</TextLink>, key: 'tasks' },
            { content: id, key: 'id' },
          ]}
        />
      </Flexed>
    </Container>
  )
}

const SubflowDetail = () => {
  const { id } = useParams<any>()

  return (
    <Container>
      <Flexed>
        <Breadcrumb
          sections={[
            { content: 'Subflows', key: 'subflows' },
            { content: id, key: 'id' },
          ]}
        />
      </Flexed>
    </Container>
  )
}

const TemplateDetail = () => {
  const { name } = useParams<any>()

  return (
    <Container>
      <Flexed>
        <Breadcrumb
          sections={[
            {
              content: <TextLink to="/templates">Templates</TextLink>,
              key: 'templates',
            },
            { content: name, key: 'name' },
          ]}
        />
      </Flexed>
    </Container>
  )
}

const Settings = () => (
  <Container>
    <Flexed>
      <Title>Settings</Title>
    </Flexed>
  </Container>
)

const DataQueryList = () => (
  <Container>
    <Flexed>
      <Title>Data queries</Title>
      <Button to="/explore" as={Link}>
        <Icon name="add_circle_outline" /> Add data query
      </Button>
    </Flexed>
  </Container>
)

const ByomlList = () => (
  <Container>
    <Flexed>
      <FlexedRow>
        <Title>Machine learning</Title>
      </FlexedRow>
    </Flexed>
  </Container>
)

const Applications = () => (
  <Container>
    <Flexed>
      <FlexedRow>
        <Title>Applications</Title>
      </FlexedRow>
    </Flexed>
  </Container>
)

const Logs = () => (
  <Container>
    <Flexed>
      <FlexedRow>
        <Title>Logs</Title>
      </FlexedRow>
    </Flexed>
  </Container>
)

const Simulator = () => (
  <Container>
    <Flexed>
      <FlexedRow>
        <Title>Simulator</Title>
      </FlexedRow>
    </Flexed>
  </Container>
)

const Topbar = () => {
  return (
    <Switch>
      <Route path="/alarms/:id">
        <AlarmDetail />
      </Route>
      <Route path="/integrations">
        <IntegrationsDetail />
      </Route>
      <Route exact path="/alarms">
        <AlarmTableView />
      </Route>
      <Route
        exact
        path="/administration/settings/organisation/integrations/:pubsubName/:tab"
      >
        <IntegrationDetail />
      </Route>
      <Route exact path="/plugins/:type/:name">
        <PlugDetail />
      </Route>
      <Route exact path="/plugins/new">
        <PlugNew />
      </Route>
      <Route exact path="/plugins">
        <PlugList />
      </Route>
      <Route exact path="/webscripts/new">
        <WebscriptNew />
      </Route>
      <Route exact path="/webscripts/:name">
        <WebscriptDetail />
      </Route>
      <Route exact path="/webscripts/:name/logs">
        <WebscriptLogView />
      </Route>
      <Route exact path="/resources">
        <ResourceList />
      </Route>
      <Route path="/resources/:id/">
        <ResourceDetail />
      </Route>
      <Route exact path="/resourcetypes">
        <ResourceTypes />
      </Route>
      <Route path="/resourcetypes/:id/">
        <ResourceTypeDetail />
      </Route>
      <Route path="/resourceconstraints/:id">
        <ResourceConstraintDetail />
      </Route>
      <Route path="/resourceconstraints">
        <ResourceConstraints />
      </Route>
      <Route exact path="/tasks">
        <TaskList />
      </Route>
      <Route path="/tasks/:id/">
        <TaskDetail />
      </Route>
      <Route path="/subflows/:id/">
        <SubflowDetail />
      </Route>
      <Route path="/templates/:name">
        <TemplateDetail />
      </Route>
      <Route path="/administration/settings">
        <Settings />
      </Route>
      <Route path="/analytics/queries">
        <DataQueryList />
      </Route>
      <Route path="/analytics/byoml">
        <ByomlList />
      </Route>
      <Route path="/applications">
        <Applications />
      </Route>
      <Route path="/logs">
        <Logs />
      </Route>
      <Route path="/simulator">
        <Simulator />
      </Route>
    </Switch>
  )
}

export default hot(Topbar)
