import styled from '@emotion/styled'
import { Button, Icon, clearSky } from '@waylay/react-components'
import { get } from 'lodash-es'
import { Message } from './Message.styles'
import logger from '~/lib/log'
import errorIllustration from '../../assets/images/fatal-error.png'
import notFoundIllustration from '../../assets/images/not-found.png'
import badRequestIllustration from '../../assets/images/bad-request.png'
import paymentRequired from '../../assets/images/payment-required.png'

const Illustration = styled.img`
  max-width: 100%;
`

const ErrorMessage = ({
  reload,
  error,
}: {
  reload?: Function
  error?: Error
}) => {
  logger.error({ err: error }, 'Something went wrong')
  const errorStatus = get(error, 'response.status')

  let imageSrc: string
  let errorMessage: string | JSX.Element = get(error, 'response.data.error')
  let couldRetry = true

  switch (errorStatus) {
    case 404:
      imageSrc = notFoundIllustration
      errorMessage =
        'We could not find what you were looking for, it either does not exist or was moved.'
      break
    case 403:
      imageSrc = badRequestIllustration
      errorMessage =
        "You don't have sufficient permissions to view this page or perform this action."
      couldRetry = false
      break
    case 402:
      imageSrc = paymentRequired
      errorMessage =
        'This feature is not included in your current pricing tier.'
      couldRetry = false
      break
    case 400:
      imageSrc = badRequestIllustration
      couldRetry = false
      break
    case 501:
      couldRetry = false
      errorMessage = (
        <div>
          The feature is not fully supported for your instance, please contact{' '}
          <a
            target="_blank"
            href="https://waylay.atlassian.net/servicedesk/customer/portal/1"
            style={{ cursor: 'pointer', color: clearSky }}
            rel="noreferrer"
          >
            Waylay Support
          </a>{' '}
          for more information
        </div>
      )

      break
    default:
      imageSrc = errorIllustration
      errorMessage =
        'Something went wrong with your connection, please try again.'
  }

  return (
    <Message>
      <div>
        <Illustration src={imageSrc} />
        <p data-testid="error-message">{errorMessage}</p>
        {reload && couldRetry && (
          <Button
            size="large"
            pill
            onClick={() => reload()}
            data-testid="refresh-button"
          >
            <Icon name="refresh" data-testid="refresh-icon" /> Try Again
          </Button>
        )}
      </div>
    </Message>
  )
}

export default ErrorMessage
