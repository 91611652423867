import React from 'react'
import VaultSelect from '~/components/Common/VaultSelect'
import { IPropertyInput } from '../PropertyInput'

const VaultField = ({ property, value, setFieldValue }: IPropertyInput) => {
  const { name, defaultValue } = property

  const handleChange = event => {
    setFieldValue(name, event?.value ?? '')
  }

  return (
    <VaultSelect
      value={value}
      onChange={handleChange}
      placeholder={defaultValue}
    />
  )
}

export default VaultField
