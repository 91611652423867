import styled from '@emotion/styled'
import { Segment } from '@waylay/react-components'

export const Head = styled(Segment)`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  min-width: 1000px;
`

export const Name = styled.header`
  font-weight: 600;
`

export const Description = styled.span`
  font-size: 0.9rem;
`

export const Metadata = styled.div`
  margin-top: 0.25rem;
`

export const Actions = styled.div`
  display: flex;

  > a,
  > button {
    margin-right: 0.5em;
  }
`

export const Details = styled.div`
  flex: 1;
`
