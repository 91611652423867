import styled from '@emotion/styled'
import { List, watermelon } from '@waylay/react-components'

const DeleteAction = styled(List.Item)`
  color: ${watermelon};

  &:hover {
    color: ${watermelon};
  }
`

export default DeleteAction
