import React, { useEffect, useRef, useState } from 'react'
import { hot } from 'react-hot-loader/root'
import { useParams, Link } from 'react-router-dom'
import {
  Button,
  Icon,
  Popup,
  Segment,
  List,
  Label,
  StreamlineIcon,
  amethyst,
} from '@waylay/react-components'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { Helmet } from 'react-helmet-async'
import { get, map } from 'lodash-es'
import { decode, encode, AnnotationType } from '@waylay/bn-parser'
import GraphEditor from '@waylay/graph-editor'
import { Menu } from '@streamlinehq/streamline-bold/lib/interface-essential'

import NodeInformation from '../Tasks/NodeConfiguration'
import { NodeInfoWrapper, GraphContainer } from '../Tasks/Debugger'
import { Head, Details, Actions } from '../Common/DetailHead'
import { AbsoluteTimestamp } from '../Common/Timestamp'
import plugsContainer from '../Plugins/usePlugs'
import ErrorMessage from '../Messages/Error'
import useTemplateFetch from './useTemplateFetch'
import { TemplateContainer } from './useTemplate'
import useTaskFormModal from '../Tasks/Detail/FormModal'
import useRemoveModal from './Remove'
import { Title, Name, Meta, MetaItem } from '../Common/Overview'
import { IfPending, IfRejected, IfFulfilled } from '../Common/SWRHelpers'
import Alerter from './Alerter'
import DangerListItem from '~/components/Common/DangerListItem'
import { WaylayEntity } from '~/lib/types'
import { createTitle } from '~/lib/util'
import { RecentItem } from '~/components/Common/RecentItem'
import Tags from '~/components/Common/Tags'
import client from '~/lib/client'
import { useAsync } from 'react-async'
import { useToasts } from 'react-toast-notifications'

export const annotationsMap = {
  [AnnotationType.dataTrigger]:
    'https://cdn.jsdelivr.net/gh/twitter/twemoji@v12.1.4/assets/72x72/26a1.png',
  [AnnotationType.tickTrigger]:
    'https://cdn.jsdelivr.net/gh/twitter/twemoji@v12.1.4/assets/72x72/23f1.png',
  error:
    'https://cdn.jsdelivr.net/gh/twitter/twemoji@v12.1.4/assets/72x72/26a0.png',
}

const DetailView = () => {
  const { id } = useParams<{ [key: string]: string }>()
  const fetchTemplate = useTemplateFetch(id)

  return (
    <>
      <IfPending state={fetchTemplate}>Loading...</IfPending>
      <IfRejected state={fetchTemplate}>
        {error => <ErrorMessage error={error} />}
      </IfRejected>
      <IfFulfilled state={fetchTemplate}>
        {data => (
          <TemplateDetail
            data={data}
            reload={fetchTemplate.reload}
            promoteToDiscoveryTemplate={
              fetchTemplate.promoteToDiscoveryTemplate
            }
            demoteDiscoveryTemplate={fetchTemplate.demoteDiscoveryTemplate}
          />
        )}
      </IfFulfilled>
    </>
  )
}

export const TemplateDetail = ({
  data,
  reload,
  promoteToDiscoveryTemplate,
  demoteDiscoveryTemplate,
  showAlerts = true,
}) => {
  const { template, taskCount, resourceTypes } = data
  const { download } = TemplateContainer.useContainer()
  const graphCanvas = useRef()
  const graphInstance = useRef()
  const plugs = plugsContainer.useContainer()
  const [focusedNodes, setFocusedNodes] = useState([])
  const focusedNode = focusedNodes.length === 1 ? focusedNodes[0] : null

  const name = get(template, 'name')

  useEffect(() => {
    // we have to wait until the DOM elem is available and the plug cache is populated
    if (!graphCanvas.current || !template) return

    const network = template

    const graph = new GraphEditor({
      elements: [],
      container: graphCanvas.current,
      editable: false,
      selectable: true,
      controls: true,
      theme: 'light',
      parser: {
        encode,
        decode: (network, options) =>
          decode(network, { ...options, annotationsMap }),
      },
    })

    graph.on('nodeFocus', () =>
      setFocusedNodes(graph.getSelectedNodes().map(n => n.data)),
    )
    graph.on('nodeBlur', () =>
      setFocusedNodes(graph.getSelectedNodes().map(n => n.data)),
    )

    graph.import(network, { plugCache: plugs.data })
    graph.fitToContainer()

    graphInstance.current = graph
  }, [graphCanvas.current, JSON.stringify(template)])

  return (
    <>
      <Helmet title={createTitle(name, 'Templates')} />
      <RecentItem id={name} name={name} type={WaylayEntity.Template} />
      <Header
        template={template}
        taskCount={taskCount}
        resourceTypes={resourceTypes}
        handleDownload={async () => await download(template)}
        onPromote={() => promoteToDiscoveryTemplate.execute()}
        onDemote={() => demoteDiscoveryTemplate.execute()}
      />
      {showAlerts && (
        <Alerter
          nodes={get(template, 'nodes', [])}
          templateId={template.name}
          taskCount={taskCount}
          onMigration={() => reload()}
        />
      )}
      <GraphWrapper padding={0}>
        <GraphContainer ref={graphCanvas} />
        {focusedNode && (
          <NodeInfoWrapper>
            <NodeInformation node={focusedNode} />
          </NodeInfoWrapper>
        )}
      </GraphWrapper>
    </>
  )
}

const MarginTop = css`
  margin-top: 1rem;
`

const GraphWrapper = styled(Segment)`
  ${MarginTop};
  position: relative;
`

const Header = ({
  template,
  taskCount,
  resourceTypes,
  handleDownload,
  onPromote,
  onDemote,
}) => {
  const { name, user, lastUpdateTime, discoveryTemplate } = template
  const popupRef = useRef(null)
  const { addToast } = useToasts()

  const reload = useAsync({
    deferFn: async () =>
      await client.tasks.batch.raw('reload', { template: template.name }),
    onReject: () => {
      addToast('Failed to reload tasks', { appearance: 'error' })
    },
    onResolve: () => {
      addToast('Successfully reloaded tasks', { appearance: 'success' })
    },
  })

  const { showModal } = useTaskFormModal({ template: name })
  const [showRemoveModal] = useRemoveModal({
    id: name,
  })

  const handlePromote = () => {
    onPromote()
    popupRef.current?.hide()
  }

  const handleDemote = () => {
    onDemote()
    popupRef.current?.hide()
  }

  return (
    <Head>
      <Details>
        <Title>
          <Name>{name}</Name>
        </Title>
        <Meta>
          {discoveryTemplate && (
            <Label outline size="tiny" color={amethyst}>
              <Icon name="wifi_tethering" /> Discovery
            </Label>
          )}
          <MetaItem>
            <Icon name="person_outline" />
            {user}
          </MetaItem>
          <AbsoluteTimestamp
            timestamp={new Date(lastUpdateTime).toISOString()}
          />
          {resourceTypes &&
            map(resourceTypes, resourceType => (
              <MetaItem>
                <Icon name="category" />
                <Link
                  to={`/resourcetypes/${encodeURIComponent(resourceType.id)}`}
                >
                  {resourceType.name}
                </Link>
              </MetaItem>
            ))}
          <Tags tags={template.tags} />
        </Meta>
      </Details>
      <Actions>
        <Button as={Link} to={`/designer?template=${name}`} outline>
          Edit in designer
        </Button>
        <Button onClick={showModal}>
          <Icon name="assignment" /> Create task
        </Button>
        <Popup
          onCreate={instance => {
            popupRef.current = instance
          }}
          placement="bottom-end"
          appendTo={() => document.body}
          content={
            <List as={Segment} padding={0} interactive>
              <List.Item as={Link} to={`/tasks?query=${`template:"${name}"`}`}>
                View {taskCount} tasks
              </List.Item>
              <List.Item onClick={() => reload.run()}>Reload tasks</List.Item>
              <List.Item onClick={handleDownload}>Download</List.Item>
              {discoveryTemplate && (
                <List.Item onClick={() => handleDemote()}>
                  Unlink discovery template
                </List.Item>
              )}
              {!discoveryTemplate && (
                <List.Item onClick={() => handlePromote()}>
                  Make discovery template
                </List.Item>
              )}
              <List.Divider />
              <DangerListItem onClick={() => showRemoveModal()}>
                <Icon name="delete_outline" /> Delete
              </DangerListItem>
            </List>
          }
        >
          <Button kind="secondary" outline>
            <StreamlineIcon
              iconData={Menu.NavigationMenuHorizontal1Alternate}
            />{' '}
            More actions
          </Button>
        </Popup>
      </Actions>
    </Head>
  )
}

export default hot(DetailView)
