import Waylay from '@waylay/client'
import Resource from '@waylay/client/dist/resource'
import Stripe from 'stripe'

interface IBillingServiceCustomer extends Stripe.customers.ICustomer {
  // eslint-disable-next-line camelcase
  invoice_preview: Stripe.invoices.IInvoice
}

export class BillingClient {
  billing: Resource

  constructor(waylayClient: Waylay) {
    this.billing = waylayClient.billing
  }

  async billingDetails(): Promise<IBillingServiceCustomer> {
    return await (this.billing._request({
      path: '/customer',
    }) as Promise<IBillingServiceCustomer>)
  }

  async paymentMethods(): Promise<
    Stripe.IList<Stripe.paymentMethods.IPaymentMethod>
  > {
    return await (this.billing._request({
      path: '/payment_methods',
    }) as Promise<Stripe.IList<Stripe.paymentMethods.IPaymentMethod>>)
  }

  async setupIntents(): Promise<
    Stripe.IList<Stripe.setupIntents.ISetupIntent>
  > {
    return await (this.billing._request({ path: '/setup_intents' }) as Promise<
      Stripe.IList<Stripe.setupIntents.ISetupIntent>
    >)
  }

  async submitBillingDetails(details): Promise<any> {
    return await this.billing._request({
      method: 'POST',
      path: '/customer',
      data: details,
    })
  }

  async createCardSetupIntent(): Promise<{
    clientSecret: string
  }> {
    return await (this.billing._request({
      method: 'POST',
      path: '/setup_intents',
    }) as Promise<{
      clientSecret: string
    }>)
  }

  async payments(): Promise<Stripe.IList<Stripe.charges.ICharge>> {
    return await (this.billing._request({ path: '/charges' }) as Promise<
      Stripe.IList<Stripe.charges.ICharge>
    >)
  }

  async attachPaymentMethod(paymentMethod: string): Promise<any> {
    return await this.billing._request({
      method: 'POST',
      path: '/attach',
      data: { paymentMethod },
    })
  }

  async cancelSubscription(): Promise<void> {
    return await (this.billing._request({
      method: 'POST',
      path: '/subscriptions/cancel',
    }) as unknown as Promise<void>)
  }

  async subscriptions(): Promise<
    Stripe.IList<Stripe.subscriptions.ISubscription>
  > {
    return await (this.billing._request({
      path: '/subscriptions',
    }) as Promise<Stripe.IList<Stripe.subscriptions.ISubscription>>)
  }

  async metrics(series?: string[]): Promise<any> {
    return await this.billing._request({
      path: '/historical',
      params: { series: [].concat(series).join(',') },
    })
  }
}
