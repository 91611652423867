import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { useModal } from 'react-modal-hook'
import { AbstractState, AsyncState, IfRejected, useAsync } from 'react-async'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { get, isEmpty, isEqual, pick, omit } from 'lodash-es'
import { useHistory } from 'react-router-dom'
import {
  Button,
  Collapsible,
  Form,
  Icon,
  Message,
  Modal,
  Segment,
  Toggle,
  Wizard,
} from '@waylay/react-components'

import {
  ITag,
  ITemplate,
  ITemplateVariable,
  PropertyType,
  TaskType,
} from '~/lib/types'
import client from '~/lib/client'
import ResourceSelect from '~/components/Common/ResourceSelect'
import TemplateSelect from '~/components/Common/TemplateSelect'
import { hasDuplicates, isRruleExpr } from '~/lib/util'
import {
  TaskTags,
  TaskTypeConfiguration,
  TaskTypeSelector,
} from '~/components/Common/TaskCreate'
import VariablesInput from '~/components/Tasks/VariablesInput'

// tags are always prefilled with empty values
export const EMPTY_TAGS = [{ key: '', value: '' }]

interface ITaskFormProps {
  template?: string
  network?: any
  task?: any
}

interface ITaskOptions extends IPeriodicTaskOptions, IScheduledTaskOptions {
  ID?: string
  name: string
  type: TaskType
  template?: string
  resource?: string
  start?: boolean
  tags?: ITag[]
  variables?: Record<string, string>
  parallel?: boolean
  resetObservations?: boolean
  gatesNeedFullObservation?: boolean
}

interface IPeriodicTaskOptions {
  frequency: number
}

interface IScheduledTaskOptions {
  cron?: string
  rrule?: string
  timeZone?: string
}

type TaskVariable = Record<string, string>

type StepKey = 'task_configuration' | 'variables_input'

const isTemplateVariable = (
  variables: ITemplateVariable[] | TaskVariable,
): variables is ITemplateVariable[] => {
  return Array.isArray(variables)
}

const createOrUpdateTaskDeferred = ([template, options = {}, variables]: [
  string,
  Partial<ITaskOptions>,
  TaskVariable,
]) => {
  if (options.tags) {
    const definedTags = options.tags.filter(({ key }) => key) // filter out empty tag keys. ('foo': '') is permitted
    const keyValueEntries = definedTags.map(({ key, value }) => [key, value])
    Object.assign(options, { tags: Object.fromEntries(keyValueEntries) })
  }

  if (!isEmpty(variables)) {
    Object.assign(options, { variables })
  }

  const { ID, ...restOptions } = options
  const taskOptions: Partial<ITaskOptions> = {
    template,
    start: true,
    variables: {},
    parallel: true,
    resetObservations: false,
    ...restOptions,
  }

  if (ID) return client.tasks.update(ID, taskOptions)
  return client.tasks.create(taskOptions)
}

const templateFetch = async ({ selectedTemplate }): Promise<ITemplate> =>
  !selectedTemplate
    ? { taskDefaults: [], variables: [] }
    : await (client.templates.get(
        selectedTemplate,
      ) as unknown as Promise<ITemplate>)

export default function ({
  task = {},
  template: propTemplate = task.template,
}: ITaskFormProps) {
  const history = useHistory()
  const [selectedTemplate, setSelectedTemplate] = useState(propTemplate)

  const createOrUpdateTask = useAsync({
    deferFn: createOrUpdateTaskDeferred,
    onResolve: (response: { ID: string }) => {
      history.push(`/tasks/${response.ID}`)
    },
  })

  const {
    data: template = { taskDefaults: [], variables: [] },
    isLoading: isLoadingTemplate,
  }: { data: ITemplate; isLoading: boolean } = useAsync({
    promiseFn: templateFetch,
    selectedTemplate,
    watchFn: (props, prevProps) =>
      props.selectedTemplate !== prevProps.selectedTemplate,
  })

  function getYupSchemaFor(
    type: PropertyType,
    mandatory: Boolean,
    errorIdentifier: String,
  ): Yup.SchemaOf<any> {
    switch (type) {
      case PropertyType.Float:
      case PropertyType.Double:
        return mandatory
          ? Yup.number().required(`${errorIdentifier} is required`)
          : Yup.number()
      case PropertyType.Integer:
      case PropertyType.Long:
        return mandatory
          ? Yup.number()
              .integer(`${errorIdentifier} cannot have decimals`)
              .required(`${errorIdentifier} is required`)
          : Yup.number().integer(`${errorIdentifier} cannot have decimals`)
      case PropertyType.Object:
        return mandatory
          ? Yup.object().required(`${errorIdentifier} is required`)
          : Yup.object()
      case PropertyType.String:
      default:
        return mandatory
          ? Yup.string().required(`${errorIdentifier} is required`)
          : Yup.string()
    }
  }

  const validationSchema = useMemo(() => {
    // template variables can be mandatory
    const variableValidationEntries =
      template?.variables?.map(
        ({ name, displayName, mandatory, type, defaultValue }) => {
          const variableIdentifier = `variable "${displayName ?? name}"`
          const schema = getYupSchemaFor(
            type,
            mandatory && !defaultValue,
            variableIdentifier,
          ).typeError(`${variableIdentifier} must be of type ${type}`)
          return [name, schema]
        },
      ) || []

    return Yup.object().shape({
      name: Yup.string().required(),
      template: !selectedTemplate ? Yup.string().required() : Yup.string(),
      resource: Yup.string(),
      type: Yup.string().oneOf(Object.values(TaskType)),
      tags: Yup.array()
        .test(
          'key-is-required-for-value',
          'Some values of tags are filled in, but no keys are provided',
          (tags: ITag[]) => {
            if (isEqual(tags, EMPTY_TAGS)) {
              return true
            }

            const emptyKeysForFilledValues = tags.filter(
              ({ key, value }) => !key && value,
            )
            return emptyKeysForFilledValues.length === 0
          },
        )
        .test(
          'no-duplicate-keys',
          'Tags contain duplicate keys',
          (tags: ITag[]) => !hasDuplicates(tags.map(({ key }) => key)),
        ),

      schedule: Yup.string(),
      frequency: Yup.number(),
      parallel: Yup.boolean(),
      resetObservations: Yup.boolean(),
      gatesNeedFullObservation: Yup.boolean(),

      variables: Yup.object().shape(
        Object.fromEntries(variableValidationEntries),
      ),
    })
  }, [JSON.stringify(template)])

  const initialValues: Record<any, any> = {}

  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues,
    onSubmit: async values => {
      const {
        type,
        resource,
        resetObservations,
        schedule,
        frequency,
        parallel,
        gatesNeedFullObservation,
        tags,
        variables,
      } = values

      const { ID } = task
      const template = propTemplate || values.template

      const taskOptions: ITaskOptions = {
        ...(ID && { ID }),
        name: values.name,
        template,
        type,
        resource,
        // only for periodic tasks
        frequency: type === TaskType.Periodic ? frequency : undefined,
        resetObservations,
        gatesNeedFullObservation,
        parallel,
        tags,
      }

      if (TaskType.Scheduled === type) {
        if (isRruleExpr(schedule)) {
          taskOptions.rrule = schedule
        } else {
          taskOptions.cron = schedule
        }
      }

      createOrUpdateTask.run(template, taskOptions, variables)
    },
    validationSchema,
  })

  // init the form defaults
  const INITIAL_FORM_VALUES = {
    name: '',
    resource: '',
    type: TaskType.Periodic,
    tags: EMPTY_TAGS,
    schedule: task.rrule ?? task.cron ?? 'FREQ=MINUTELY;INTERVAL=15',
    frequency: task.frequency ?? 900000,
    ...omit(task, ['cron', 'rrule']),

    /**
     * Advanced settings
     * If no task is passed, set defaults for console (true, false). If a task
     * is passed via props and it doesn't include one of the attributes,
     * replicate engine default behaviour (true, true)
     */
    parallel: isEmpty(task) ? true : task.parallel ?? true,
    resetObservations: isEmpty(task) ? true : task.resetObservations ?? true,
    gatesNeedFullObservation: isEmpty(task)
      ? false
      : task.gatesNeedFullObservation ?? false,
  }

  useEffect(() => {
    const variables: ITemplateVariable[] | TaskVariable =
      task?.variables || template?.variables

    // if the task has a ID we do an edit and no defaults from template should be taken.
    const taskDefaults: { [key: string]: any } = task?.ID
      ? {}
      : omit(template?.taskDefaults, ['cron', 'rrule'])
    if (!isEmpty(taskDefaults)) {
      taskDefaults.schedule =
        template?.taskDefaults?.rrule || template?.taskDefaults?.cron
    }

    const valuesToKeep = pick(formik.values, ['name', 'template'])

    formik.resetForm()
    formik.setValues({
      ...INITIAL_FORM_VALUES,
      ...taskDefaults,
      ...valuesToKeep,
      variables: isTemplateVariable(variables)
        ? Object.fromEntries(variables.map(({ name }) => [name, '']))
        : variables,
    })
  }, [JSON.stringify(template), JSON.stringify(task)])

  useEffect(() => {
    createOrUpdateTask.promise.then(() => hideModal()).catch(() => {})
  }, [createOrUpdateTask.promise])

  const steps = [
    <TaskConfiguration
      key="task_configuration"
      task={task}
      formik={formik}
      propTemplate={propTemplate}
      setSelectedTemplate={setSelectedTemplate}
    />,
    !isEmpty(template.variables) && (
      <VariablesInput
        key="variables_input"
        variableSpecs={template.variables}
        getFieldProps={formik.getFieldProps}
        setFieldValue={formik.setFieldValue}
      />
    ),
  ].filter(Boolean) // filters out the 'variables' part of the task when it's undefined

  const [showModal, hideModal] = useModal(
    () => (
      <Modal isOpen onRequestClose={hideModal}>
        <Form onSubmit={formik.handleSubmit}>
          <Wizard steps={steps}>
            {({ currentStep, next, previous, isFirst, totalSteps, isLast }) => (
              <ModalWrapper>
                {currentStep}
                <Errors
                  errors={formik.errors}
                  asyncState={createOrUpdateTask}
                />
                <Segment.Footer style={{ display: 'flex' }}>
                  <div style={{ flexGrow: 1 }}>
                    <Button outline kind="secondary" onClick={hideModal}>
                      Cancel
                    </Button>
                  </div>
                  {totalSteps !== 1 && (
                    <Button
                      disabled={isFirst}
                      onClick={e => {
                        e.preventDefault()
                        previous()
                      }}
                      outline
                    >
                      Previous
                    </Button>
                  )}
                  {!isLast ? (
                    <Button
                      disabled={isLast}
                      onClick={e => {
                        e.preventDefault()
                        next()
                      }}
                      style={{ marginLeft: '5px' }}
                    >
                      Next
                    </Button>
                  ) : (
                    <Button
                      type="submit"
                      kind="primary"
                      loading={
                        createOrUpdateTask.isLoading || isLoadingTemplate
                      }
                      disabled={
                        createOrUpdateTask.isLoading || isLoadingTemplate
                      }
                      onClick={formik.handleSubmit}
                    >
                      {task.ID ? 'Update task' : 'Create task'}
                    </Button>
                  )}
                </Segment.Footer>
              </ModalWrapper>
            )}
          </Wizard>
        </Form>
      </Modal>
    ),
    [createOrUpdateTask, formik.values, formik.errors, formik.handleSubmit],
  )

  return {
    showModal,
    hideModal,
  }
}

interface ITaskConfigurationProps {
  task: any
  formik: any
  propTemplate: any
  setSelectedTemplate: any
  key: StepKey
}

const TaskConfiguration = ({
  task,
  formik,
  propTemplate,
  setSelectedTemplate,
  key,
}: ITaskConfigurationProps) => {
  return (
    <Fragment key={key}>
      <Segment.Header>
        {task.ID ? 'Edit task' : 'Create new task'}
      </Segment.Header>
      <Segment>
        <label htmlFor="name">Name*</label>
        <Form.Input.Group fluid>
          <Form.Input
            id="name"
            autoFocus
            fluid
            {...formik.getFieldProps('name')}
          />
        </Form.Input.Group>

        {!propTemplate && (
          <ResourceWrapper>
            <label htmlFor="template">Template*</label>
            <TemplateSelect
              template={formik.values.template}
              onChange={({ value }) => {
                setSelectedTemplate(value)
                formik.setErrors({})
                formik.setFieldValue('template', value)
                formik.setFieldValue('variables', {})
              }}
            />
          </ResourceWrapper>
        )}

        <ResourceWrapper>
          <label htmlFor="resource">Resource</label>
          <ResourceSelect
            resource={formik.values.resource}
            onChange={({ value }) => formik.setFieldValue('resource', value)}
          />
        </ResourceWrapper>
        <TaskTags
          setTags={tags => formik.setFieldValue('tags', tags)}
          tags={formik.values.tags}
        />
        <TaskTypeSelector
          taskType={formik.values.type}
          setTaskType={type => formik.setFieldValue('type', type)}
        />
      </Segment>
      <TaskTypeConfiguration
        schedule={formik.values.schedule}
        scheduleChange={schedule => formik.setFieldValue('schedule', schedule)}
        frequency={formik.values.frequency}
        frequencyChange={frequency =>
          formik.setFieldValue('frequency', frequency)
        }
        taskType={formik.values.type}
      />
      <Collapsible>
        {({ isOpen, toggle, style }) => (
          <>
            <ToggleHeader onClick={() => toggle()}>
              <Icon
                name={isOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
              />{' '}
              Advanced configuration
            </ToggleHeader>
            <Segment style={style} padding={isOpen ? '1rem' : 0}>
              <ToggleField>
                <Toggle
                  id="parallel"
                  name="parallel"
                  checked={formik.values.parallel}
                  onChange={formik.handleChange}
                />
                <label htmlFor="parallel">Execute sensors in parallel</label>
              </ToggleField>
              <ToggleField>
                <Toggle
                  id="resetObservations"
                  name="resetObservations"
                  checked={formik.values.resetObservations}
                  onChange={formik.handleChange}
                />
                <label htmlFor="resetObservations">
                  Reset observations on each invocation
                </label>
              </ToggleField>
              <ToggleField>
                <Toggle
                  id="gatesNeedFullObservation"
                  name="gatesNeedFullObservation"
                  checked={formik.values.gatesNeedFullObservation}
                  onChange={formik.handleChange}
                />
                <label htmlFor="gatesNeedFullObservation">
                  Only evaluate gates when all inputs are observed
                </label>
              </ToggleField>
            </Segment>
          </>
        )}
      </Collapsible>
    </Fragment>
  )
}

const ToggleHeader = styled(Segment.Header)`
  user-select: none;
  cursor: pointer;
`

const Errors = ({
  errors: _errors = {},
  asyncState,
}: {
  errors: Record<string, any>
  asyncState?: AsyncState<any, AbstractState<any>>
}) => {
  const { variables = {}, ...errors } = _errors
  const hasErrors = Boolean(!isEmpty(_errors) || asyncState.error)

  if (!hasErrors) {
    return null
  }

  return (
    <Segment>
      <div
        css={css`
          margin-bottom: 1rem;
        `}
      >
        {Object.keys(errors).map(field => (
          <FormError key={field} error={errors[field]} />
        ))}
        {Object.keys(variables).map(field => (
          <FormError key={field} error={variables[field]} />
        ))}
        <IfRejected state={asyncState}>
          {error => <FormError error={error} />}
        </IfRejected>
      </div>
    </Segment>
  )
}

const FormError = ({ error }) => {
  if (!error) {
    return null
  }

  const message =
    typeof error === 'string'
      ? error
      : get(error, 'response.data.error') || error.message

  return (
    <div
      css={css`
        margin-top: 0.5rem;
      `}
    >
      <Message kind="danger">{message}</Message>
    </div>
  )
}

const ToggleField = styled(Form.Field)`
  display: flex;
  align-items: center;

  > label {
    margin-left: 0.5em;
    user-select: none;
  }
`

const ModalWrapper = styled(Segment.Group)`
  width: 600px;
`

const ResourceWrapper = styled.div`
  margin-top: 0.5rem;
`
