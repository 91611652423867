import { IMessage } from '../useTimeseries'

export interface IChart {
  name: string
  id: string
  series: ISerie[]
}

export interface IQueryConfig {
  from?: string
  until?: string
  window?: string
  grouping: Grouping
  series: ISerie[]
}

export enum Grouping {
  None = 'none',
  Minute = 'PT1M',
  FifteenMinutes = 'PT15M',
  Hour = 'PT1H',
  EightHours = 'PT8H',
  TwentyFourHours = 'PT24H',
}

export interface ISerie {
  resource: string
  metric: string
  aggregation?: Aggregation
  seriesId?: string
}

export interface ITimeSeriesQuery extends ISerie {
  data: ISerie[]
  freq: Grouping
  'render.mode': string
  until?: string
  from?: string
  window?: string
}

export interface IDataQueryResponse {
  meta: any
  data: any[]
  messages: IMessage[]
  query: ITimeSeriesQuery
}

export interface IQueryStringParams {
  from?: string
  until?: string
  window?: string
  grouping?: string
  series?: string
}

export enum ChartPrecision {
  millisecond = 'millisecond',
  second = 'second',
  seconds = 'second',
  minute = 'minute',
  minutes = 'minute',
  hour = 'hour',
  hours = 'hour',
  day = 'day',
  days = 'day',
  month = 'month',
  months = 'month',
  year = 'year',
}
export enum Aggregation {
  Mean = 'mean',
  Median = 'median',
  First = 'first',
  Last = 'last',
  Sum = 'sum',
  Minimum = 'min',
  Maximum = 'max',
  Count = 'count',
  StandardDeviation = 'std',
}

export interface DiffObject {
  years?: number
  months?: number
  days?: number
  hours?: number
  minutes?: number
  seconds?: number
}
export interface ChartData {
  id: string
  data: DataPoint[]
}

export interface DataPoint {
  x: string
  y: number
}

export interface xAxisConfig {
  precision?: ChartPrecision
  format?: string
  interval?: string
}

export interface ExactValue {
  exactValue: number
  value: number
  type: string
}

export interface ExactMapping {
  minute: ExactValue[]
  hour: ExactValue[]
  day: ExactValue[]
  month: ExactValue[]
  year: ExactValue[]
}

export interface MinMaxValues {
  min: number
  max: number
  value: number
}

export interface Mappings {
  second: MinMaxValues[]
  minute: MinMaxValues[]
  hour: MinMaxValues[]
  day: MinMaxValues[]
  month: MinMaxValues[]
  year: MinMaxValues[]
}

export interface IDataTypes {
  id: string
  type: SeriesTypes
}

export enum SeriesTypes {
  String = 'String',
  Numeric = 'Numeric',
}

export enum DND_Accept_Types {
  String = 'metric-numeric',
  Numeric = 'metric-string',
}
