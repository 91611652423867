import React from 'react'
import { css } from '@emotion/core'
import {
  Button,
  Modal,
  Segment,
  List,
  Wizard,
  Icon,
} from '@waylay/react-components'
import { useModal } from 'react-modal-hook'
import Upload from './Upload'
import Configuration from './Configuration'
import { ImportType, OpenApiContainer } from './useUploadOpenApiPlug'
import _, { isEmpty } from 'lodash-es'
import './OpenApiWizard.css'
import { useHistory } from 'react-router-dom'
import { generatePlugin } from '@waylay/oas-generator'
import client from '~/lib/client'

const stepDescription = {
  0: 'Upload OpenAPI Specifications',
  1: 'Generate Plugin',
}

const OpenApiModal = () => {
  const history = useHistory()
  const [showModal, hideModal] = useModal(() => {
    return (
      <OpenApiContainer.Provider>
        <Modal isOpen onRequestClose={hideModal}>
          <div
            css={css`
              min-width: 600px;
            `}
          >
            <Wizard
              steps={[
                <Upload key="upload" />,
                <Configuration key="configuration" />,
              ]}
            >
              {({
                currentIndex,
                currentStep,
                next,
                previous,
                isFirst,
                isLast,
              }: {
                currentIndex: number
                currentStep: number
                next: any
                previous: any
                isFirst: boolean
                isLast: boolean
              }) => {
                const {
                  file,
                  setValidationError,
                  url,
                  validationError,
                  plug,
                  importType,
                  validateApi,
                  api,
                  isPlugNameAllowed,
                  setGeneratePlugError,
                  isDirty,
                  setIsInCreation,
                  isInCreation,
                } = OpenApiContainer.useContainer()

                const nextSteps = async (next: () => void) => {
                  if (isFirst) {
                    try {
                      if (isDirty || isEmpty(api)) {
                        await validateApi(
                          importType === ImportType.File ? file : url,
                        )
                      }
                      next()
                    } catch (err: any) {
                      setValidationError(err.message)
                    }
                  } else {
                    try {
                      setIsInCreation(true)
                      const codeGeneratedPlug = await generatePlugin(
                        api,
                        plug.path,
                        plug.operation,
                        plug.server,
                        plug.name,
                        plug.security,
                        plug.flow,
                      )
                      const codeGeneratedPlugJson =
                        JSON.parse(codeGeneratedPlug)
                      await client.sensors.create(codeGeneratedPlugJson)
                      history.replace(`/plugins/sensors/${plug.name}`)
                    } catch (err) {
                      setIsInCreation(false)
                      setGeneratePlugError(err.message)
                    }
                  }
                }

                const isDisabled =
                  (isFirst &&
                    ((!url && !file) ||
                      (importType === ImportType.File && validationError))) ||
                  (isLast &&
                    (_.isEmpty(plug) ||
                      _.isEmpty(plug?.path) ||
                      _.isEmpty(plug?.name) ||
                      _.isEmpty(plug?.operation) ||
                      !isPlugNameAllowed))

                return (
                  <Segment.Group style={{ padding: 0 }}>
                    <Segment.Header data-testid="current-step">
                      Step {currentIndex + 1}: {stepDescription[currentIndex]}
                    </Segment.Header>
                    <Segment
                      style={{
                        padding: 0,
                        width: currentStep === 1 ? '90%' : 'auto',
                      }}
                    >
                      {currentStep}
                    </Segment>
                    {!isInCreation && (
                      <Segment.Footer>
                        {!isFirst && (
                          <Button
                            data-testid="previous-button"
                            kind="primary"
                            onClick={() => previous()}
                          >
                            Previous
                          </Button>
                        )}
                        <Button
                          data-testid="next-button"
                          kind="primary"
                          disabled={isDisabled}
                          onClick={() => nextSteps(next)}
                          style={{ marginLeft: '5px' }}
                        >
                          {isLast ? 'Add plugin' : 'Validate'}
                        </Button>
                      </Segment.Footer>
                    )}
                  </Segment.Group>
                )
              }}
            </Wizard>
          </div>
        </Modal>
      </OpenApiContainer.Provider>
    )
  }, [])

  return (
    <List.Item onClick={showModal} data-testid="item-upload-openapi">
      <Icon name="folder" data-testid="button-upload-openapi" /> Upload OpenAPI
    </List.Item>
  )
}
export default OpenApiModal
