import { PlugType } from '~/components/Plugins/usePlug'

export interface AlarmHistoryItem {
  id: string
  text: string
  timestamp: string
  type: string
  changes: IChange[]
}

export interface IChange {
  attribute: string
  type: string
  oldValue: AlarmStatus | string
  newValue: AlarmStatus | string
}

export enum AlarmSeverity {
  Warning = 'WARNING',
  Minor = 'MINOR',
  Major = 'MAJOR',
  Critical = 'CRITICAL',
}

export enum AlarmStatus {
  Active = 'ACTIVE',
  Acknowledged = 'ACKNOWLEDGED',
  Cleared = 'CLEARED',
}

export interface Alarm {
  count: number
  creationTime: string
  history: AlarmHistoryItem[]
  id: string
  self: string
  severity: AlarmSeverity
  source: {
    id: string
  }
  status: AlarmStatus
  text: string
  timestamp: string
  type: string
  lastTriggeredTime: string
  lastUpdateTime: string
}

export enum TaskType {
  OneTime = 'onetime',
  Periodic = 'periodic',
  Scheduled = 'scheduled',
  Reactive = 'reactive',
}

export enum TaskStatus {
  Running = 'running',
  Stopped = 'stopped',
  Failed = 'failed',
}

export type FetchedTags = Record<string, string>

export interface ITag {
  key: string
  value: string
}

export interface INode {
  name: string
}

export interface IInfoBox {
  x: number
  y: number
  text: string
}

export interface ITaskTemplate {
  name: string
  user: string
  metadata?: {
    infoBoxes: IInfoBox[]
  }
  variables?: {
    [key: string]: string
  }
  createTime: number
  lastUpdateTime: number
  nodes: INode[]
  simplifiedGraph: any
  discoveryTemplate: boolean
}

export interface ITemplateConnection {
  type: TaskType
  parallel: boolean
  resetObservations: boolean
  templateName: string
  frequency?: number
}

interface IActuatorInvocation {
  node: string
  action: string // name of the actuator
  time: number // epoch millis
  error?: string
}

interface ITaskHistory {
  [key: string]: Array<{
    state: string
    time: number
    probability: number
  }>
}

export interface ITask {
  commands: string[]
  createTime: number
  cron?: string
  rrule?: string
  frequency?: number
  history: ITaskHistory
  ID: string
  template?: string
  lastExecutionTime: number
  name: string
  network: {
    nodes?: any[]
  }
  rawData: object
  resetObservation: boolean
  actuatorsLastInvocations?: IActuatorInvocation[]
  resourceIds: string[]
  startTime: number
  status: TaskStatus
  tags: object
  type: TaskType
  user: string
  variables?: object
  messages?: string[]
}

export interface IBindingSchema {
  globalSettings: Object
  node: Object
  nodes: Record<string, Object>
  streamdata: {
    timestamp: string
  }
  task: {
    ACTION_NODE: string | null
    NODE_NAME: string | null
    resource: Object
    TASK_ID: string | null
    variables: Record<string, any | null>
    vault: Record<string, string>
  }
}

export enum LogLevel {
  Error = 'ERROR',
  Warning = 'WARN',
  Info = 'INFO',
  Debug = 'DEBUG',
}

export interface IProvider {
  name: string
  prettyName: string
  auth: {
    type: string
    authorizeUrl: string
  }
  isConnected: boolean
}

export interface ITemplate {
  name?: string
  prettyName?: string
  fields?: object[]
  variables?: any[]
  taskDefaults?: { [key: string]: any }
}

export interface IStatus {
  name: string
  status: string
  provider: string
  lastMessage: number
  error?: {
    message: string
  }
}

export interface IPlugProperty {
  name: string
  type: PropertyType
  mandatory?: boolean
  sensitive?: boolean
  defaultValue: any
  format: {
    type: PropertyFormatType
    values?: Object[]
    strict: boolean
  }
}

export interface IRawDataProperty {
  dataType: PropertyType
  parameter: string
}

export interface Plug {
  type: PlugType
  name: string
  version: string
  author: string
  description: string
  mediaType: 'application/javascript' | 'application/java-vm'
  iconURL: string
  category: string
  configuration: IPlugProperty[]
  isDeprecated?: boolean
  tags: string[]
  metadata?: { tags: IPlugTag[] }
  status: 'running' | 'killed' | 'pending' | 'failed'
}

export interface Webscript {
  versions: {
    '1.0.0': WebscriptVersion
  }
  secret: string
}

export interface IWebscriptPlugProperties {
  private: boolean
}

export enum EWebscriptStatus {
  Running = 'running',
  Pending = 'pending',
  Failed = 'failed',
}

export interface WebscriptVersion {
  name: string
  version: string
  description: string
  author: string
  main?: string
  dependencies?: object
  script: string
  waylay: {
    iconURL: string
  }
  webscript?: IWebscriptPlugProperties
  status?: EWebscriptStatus | string
  operations?: WebscriptOperation[]
  failedReason?: {
    log: string[]
    events: string[]
  }
}

export interface NewWebscript {
  name: string
  version: string
  script: string
  dependencies: object
  metadata: {
    author: string
    description: string
  }
}

export enum Status {
  enabled = 'enabled',
  disabled = 'disabled',
  unverified = 'unverified',
}

export interface IUser {
  id: string
  email: string
  firstname: string
  lastname: string
  fullname: string
  timezone: string
  status: Status
  twofa: boolean
  roles: IRole[]
  /* eslint-disable camelcase */
  created_at: string
  updated_at: string
  last_login: string
  /* eslint-enable camelcase */
}

export interface ITenant {
  id: string
  name: string
  domain: string
  description: string
  status: Status
  twofa: boolean
}

export interface IRole {
  id: string
  name: string
  description: string
  tenant: ITenant
  permissions: IPermission[]
  /* eslint-disable camelcase */
  created_at: string
  updated_at: string
  /* eslint-enable camelcase */
  predefined?: boolean
}

export enum PermissionScope {
  all = 'all',
  me = 'me',
}

export enum PermissionOperation {
  create = 'create',
  read = 'read',
  update = 'update',
  delete = 'delete',
}

export interface IPermission {
  id: string
  name: string
  description: string
  scope: PermissionScope
  operation: PermissionOperation
}

export interface Operation {
  name: string
  metadata: object
  done: boolean
  error: {
    code: number
    message: string
    details: object
  }
  response: object
}

export interface WebscriptOperation {
  name: string
}

export interface IJsonWebTokenBody {
  tenant?: string
  licenses?: string[]
  exp?: number
  domain?: string
  groups?: string[]
  permissions?: string[]
  iat?: number
  iss?: string
  sub?: string
}

export enum ConstraintStatus {
  Ineffect = 'ineffect',
  Applying = 'applying',
  Failed = 'failed',
}

export enum ConstraintTypes {
  numeric = 'numeric',
  string = 'string',
  boolean = 'boolean',
  object = 'object',
  array = 'array',
  enum = 'enum',
  resourceRef = 'resourceRef',
}

export enum PropertyType {
  String = 'string',
  Integer = 'integer',
  Double = 'double',
  Object = 'object',
  Boolean = 'boolean',
  Long = 'long',
  Float = 'float',
}

export interface IProperty {
  name: string
  type: PropertyType
  mandatory?: boolean
  buttonText?: string
  defaultValue?: any
  format?: {
    type: PropertyFormatType
    values?: Object[]
    strict: boolean
  }
}

export interface ISetting {
  key: string
  value?: string
}

export enum PropertyFormatType {
  Enum = 'enum',
  Resource = 'resource',
  Vault = 'vault',
  Duration = 'duration',
  Code = 'code',
  Template = 'template',
}

export enum WaylayEntity {
  Alarm = 'Alarm',
  Resource = 'Resource',
  ResourceType = 'ResourceType',
  Constraint = 'Constraint',
  Plugin = 'Plugin',
  Task = 'Task',
  Template = 'Template',
  Webscript = 'Webscript',
  Storage = 'Storage',
  DataQuery = 'DataQuery',
  BYOML = 'BYOML',
  Subflow = 'Subflow',
}

export interface IErrorResponse extends Error {
  response: {
    data: {
      error: string
      status: number
      details?: any[]
    }
  }
}
export interface ITemplateError extends Error {
  code: string
  error: string
  statusCode: number
  response?: {
    data: IValidationError
    config: {
      data: string
    }
  }
}

type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>
export type NodeValidationError = IValidationDetailError

export interface IValidationError extends ITemplateError {
  code: 'VALIDATION'
  details: IValidationDetailError[]
}

export type FieldValidationError = Omit<
  Required<NodeValidationError>,
  'suggestion'
>
export type GlobalValidationError = PartialBy<IValidationDetailError, 'details'>

export interface IValidationDetailError {
  type: string
  message: string
  severity: 'ERROR' | 'WARNING'
  details: {
    nodeId: string
    actuatorLabel: string
    plugin: Object
    property?: string
    binding?: string
    cycle?: string[]
  }
  suggestion?: string
}

export function isValidationError(
  error: ITemplateError | Error,
): error is IValidationError {
  return (error as ITemplateError).code === 'VALIDATION'
}

export interface IPlugTag {
  color: string
  name: string
}

export interface ITemplateVariable extends IProperty {
  displayName: string
}

export enum WebscriptSeverity {
  Default = 'DEFAULT',
  Debug = 'DEBUG',
  Notice = 'NOTICE',
  Info = 'INFO',
  Warning = 'WARNING',
  Error = 'ERROR',
  Critical = 'CRITICAL',
  Alert = 'ALERT',
  Emergency = 'EMERGENCY',
}

export enum WebscriptMethods {
  GET = 'GET',
  POST = 'POST',
  DELETE = 'DELETE',
  PUT = 'PUT',
  PATCH = 'PATCH',
}
