import React, { useRef, useCallback } from 'react'
import { Button, Icon, Segment, Modal } from '@waylay/react-components'
import { useModal } from 'react-modal-hook'
import { css } from '@emotion/core'
import { Rnd } from 'react-rnd'
import Editor from '~/components/Common/Editor'
import { useSafeEditorContainer } from './EditorContext'
import { IPropertyInput } from './PropertyInput'
import styled from '@emotion/styled'
import { IProperty, PropertyType } from '~/lib/types'

const EditorButton = styled.div`
  height: 100%;
`

interface IOpenInEditor extends IPropertyInput {
  supportedLanguages?: string[]
}

const OpenInEditor = ({
  setFieldValue: _setFieldValue,
  property,
  value = '',
  supportedLanguages,
  isDeclarativelyBound,
}: IOpenInEditor) => {
  const editorRef = useRef(null)

  const editorSyntaxes = isObject(property)
    ? isDeclarativelyBound
      ? ['declarative-binding', 'json']
      : ['json']
    : supportedLanguages

  const editorContainer = useSafeEditorContainer()
  const declarativeBindingSchema = editorContainer
    ? editorContainer.declarativeBindingSchema
    : null

  const setFieldValue = useCallback(
    (value: any) => {
      if (isObject(property)) {
        try {
          _setFieldValue(JSON.parse(property.name), value)
        } catch (_) {
          _setFieldValue(property.name, value)
        }
        return
      }

      _setFieldValue(property.name, value)
    },
    [property, _setFieldValue],
  )

  const saveContent = () => {
    const value = editorRef.current.editor.getValue()
    setFieldValue(value)
    hideModal()
  }

  const [openModal, hideModal] = useModal(() => {
    return (
      <Modal isOpen onRequestClose={saveContent} shouldCloseOnEsc={false}>
        <Rnd
          cancel=".editor"
          minHeight={200}
          minWidth={300}
          default={{
            x: -295,
            y: 0,
            width: 600,
            height: 500,
          }}
        >
          <Segment.Group
            css={css`
              width: 100%;
              height: 100%;
            `}
          >
            <Segment.Header>
              <span
                css={css`
                  flex: 1;
                  height: 15px;
                `}
              >
                Editor · {property.name}
              </span>
              <Button
                size="small"
                css={css`
                  align-self: flex-end;
                `}
                onClick={saveContent}
              >
                <Icon name="save" />
                {property?.buttonText ? property.buttonText : ' Save'}
              </Button>
            </Segment.Header>
            <Segment
              className="editor"
              padding={0}
              css={css`
                height: 90%;
                display: flex;
              `}
            >
              <Editor
                ref={editorRef}
                script={value}
                context={{ declarativeBindingSchema }}
                supportedLanguages={editorSyntaxes}
              />
            </Segment>
          </Segment.Group>
        </Rnd>
      </Modal>
    )
  }, [property.name, value, JSON.stringify(declarativeBindingSchema)])

  return (
    <EditorButton>
      <Button
        kind="secondary"
        outline
        style={{ marginRight: 0, marginLeft: 5 }}
        onClick={() => openModal()}
      >
        <Icon name="code" />
      </Button>
    </EditorButton>
  )
}

function isObject(property: IProperty) {
  return property.type === PropertyType.Object
}

export default OpenInEditor
